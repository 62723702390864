import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { styled } from "@styles";

var getEdgeColor = function getEdgeColor(_ref) {
  var active = _ref.active,
      error = _ref.error,
      theme = _ref.theme;
  var hovered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (error) {
    return theme.colors.error;
  }

  if (hovered) {
    return theme.colors.secondary;
  }

  return active ? theme.colors.secondary : theme.colors.dark;
};

export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  border: 1px solid ", ";\n  color: ", ";\n  outline: ", ";\n  transition: all 0.3s ease;\n\n  &:hover {\n    color: ", ";\n    outline-width: 1px;\n    outline-style: solid;\n    border-color: ", ";\n    outline-color: ", ";\n  }\n"])), function (props) {
  return getEdgeColor(props);
}, function (props) {
  return getEdgeColor(props);
}, function (props) {
  return props.active ? "1px solid ".concat(getEdgeColor(props), ";") : "none";
}, function (props) {
  return getEdgeColor(props, true);
}, function (props) {
  return getEdgeColor(props, true);
}, function (props) {
  return getEdgeColor(props, true);
});
export var Content = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
export var InputWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n"])));