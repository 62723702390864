import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { styled } from '@styles';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-top: 30px;\n  margin-top: 30px;\n  border-top: 1px solid rgba(50,50,50,0.1);\n\n  ", "\n"])), function (props) {
  return props.detail && "\n    margin-bottom: 30px;\n  ";
});
export var TimeLimit = styled.p(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  font-weight: 400;\n  margin-bottom: 40px;\n\n  span {\n    font-weight: 700;\n  }\n\n  ", "\n"])), function (props) {
  return props.detail && "\n    margin-bottom: 20px;\n  ";
});
export var AmountDetail = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin-bottom: 40px;\n  ", "\n  \n  p {\n    font-size: 16px;\n    font-weight: 400;\n    margin-bottom: 10px;\n  }\n\n  span {\n    font-weight: bold;\n    font-size: 24px;\n    line-height: 29px;\n  }\n  \n  button {\n    margin-left: 20px;\n    top: -8px;\n    position: relative;\n  }\n"])), function (props) {
  return props.detail && "\n    margin-bottom: 20px;\n  ";
});
export var VirtualNumber = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-bottom: 21px;\n\n  p {\n    font-size: 16px;\n    font-weight: 400;\n    margin-bottom: 10px;\n  }\n\n  span {\n    font-weight: bold;\n    font-size: 24px;\n    line-height: 29px;\n  }\n\n  button {\n    margin-left: 20px;\n    top: -8px;\n    position: relative;\n  }\n"])));
export var FooterInfo = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12.8px;\n  line-height: 19px;\n  color: #787878;\n"])));