import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16;

import { styled } from "@styles";
export var AddToCartSelection = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var ProductNameHeader = styled.h3(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: Nunito Sans;\n  font-style: normal;\n  font-weight: 800;\n  font-size: 30px;\n  line-height: 41px;\n  color: #0a2540;\n"])));
export var CategoryText = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 19px;\n  color: #919191;\n"])));
export var ProductPricing = styled.h4(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin: 10px 0;\n  font-weight: ", ";\n\n  & > label {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 18px;\n    line-height: 25px;\n    color: #0a2540;\n    margin-right: 20px;\n  }\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var AddonsEnumerated = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 40%;\n  margin-top: 20px;\n  position: relative;\n"])));
export var AddonsNumeric = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-top: 20px;\n  width: 60%;\n\n  & > label {\n    display: block;\n    margin-bottom: 25px;\n  }\n"])));
export var ContainerNumeric = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  top: -13px;\n\n  & > span {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 18px;\n    line-height: 25px;\n    color: #0a2540;\n    position: relative;\n    left: 30px;\n  }\n"])));
export var Description = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  padding-top: 10px;\n  margin-top: 10px;\n  border-top: 1px solid #dedede;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 160%;\n  color: #787878;\n  overflow: hidden;\n  transition: height ease 0.5s;\n  height: ", ";\n\n  p {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 160%;\n    color: #787878;\n  }\n\n  .show-more {\n    color: #00ab4e;\n  }\n\n  a {\n    color: #0095da;\n    text-decoration: underline;\n  }\n"])), function (props) {
  return props.expanded ? 'auto' : '80px';
});
export var ShowMore = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  color: #00ab4e;\n  cursor: pointer;\n  text-decoration: underline;\n  font-size: 14px;\n"])));
export var Price = styled.span(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 25px;\n  line-height: 34px;\n  color: #0095da;\n  position: relative;\n"])));
export var Alert = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  font-family: Nunito Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 22px;\n  color: #0a2540;\n"])));
export var AlertRed = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  font-family: Nunito Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 22px;\n  color: red;\n"])));
export var UndiscountedPrice = styled.span(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  margin-top: 10px;\n  display: block;\n\n  & > i {\n    background: $blue;\n    color: #fff;\n    font-size: 10px;\n    padding: 4px;\n    border-radius: 3px;\n    background: #0bc10a;\n    color: #fff;\n    font-size: 12px;\n    padding: 4px;\n    border-radius: 3px;\n  }\n\n  & > span {\n    text-decoration: line-through;\n    color: ", ";\n    font-size: ", ";\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 18px;\n    line-height: 25px;\n    text-decoration-line: line-through;\n    color: #b6b6b6;\n  }\n"])), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var VariantPicker = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  display: grid;\n  margin-top: 20px;\n\n  .react-select-wrapper,\n  .input {\n    width: 50%;\n    margin-bottom: 1rem;\n  }\n"])));
export var QuantityInput = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  margin-top: 20px;\n  padding-top: 20px;\n  width: 30%;\n"])));
export var ErrorMessage = styled.p(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), function (props) {
  return props.theme.colors.error;
});