import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { styled } from "@styles";
export var Form = styled.form(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 2rem 0;\n"])));
export var Card = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-areas:\n    \"cardNumber cardNumber cardNumber cardNumber cardNumber \"\n    \"cardExpiry cardExpiry cardExpiry cardCvc cardCvc\";\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n  grid-gap: ", ";\n\n  .StripeElement {\n    padding: 0.8rem 1rem;\n    margin: 0;\n    width: 100%;\n    font-size: ", ";\n    outline: none;\n    background-color: transparent;\n    ::placeholder {\n      font-size: 0;\n      visibility: hidden;\n    }\n    &--empty {\n      font-size: 0;\n    }\n  }\n"])), function (props) {
  return props.theme.spacing.fieldSpacer;
}, function (props) {
  return props.theme.typography.baseFontSize;
});
export var CardNumberField = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  grid-area: cardNumber;\n"])));
export var CardExpiryField = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-area: cardExpiry;\n"])));
export var CardCvcField = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  grid-area: cardCvc;\n"])));