import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-start;\n"])));
export var TileWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  height: auto;\n"])));
export var Header = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 95%;\n  border-bottom: 1px solid ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 4rem;\n"])), function (props) {
  return props.theme.colors.dividerDark;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h4FontSize;
});
export var Form = styled.form(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var HeaderSmall = styled(Header)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: none;\n"])));
export var Content = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  padding: 1.5rem 0;\n  width: 95%;\n"])));
export var ContentOneLine = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 70%;\n  ", "\n"])), media.smallScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    flex-direction: column;\n    width: 100%;\n  "]))));
export var ContentDetailUp = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 95%;\n  margin-top: 20px;\n  ", "\n"])), media.smallScreen(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    flex-direction: column;\n    width: 100%;\n  "]))));
export var ContentEdit = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  width: 50%;\n  ", "\n"])), media.smallScreen(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n     width: 100%;\n  "]))));
export var ContentEditOneLine = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n\n  > div {\n    width: 48%;\n    ", "\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n      width: 100%;\n    "]))), media.smallScreen(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n     flex-direction: column;\n  "]))));
export var ContentExtendInput = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  width: 60%;\n"])));
export var FormButtons = styled.div(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  height: 5rem;\n  padding-top: 2rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  button {\n    margin-left: 2rem;\n  }\n"])));