import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  visibility: hidden;\n"])));
export var Grid = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 30px;\n  grid-template-columns: repeat(2, 1fr);\n\n  ", "\n"])), media.smallScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    grid-template-columns: repeat(1, 1fr);\n  "]))));
export var Divider = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 0 0 20px 0;\n"])), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var SectionPrice = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  border-top: 1px solid #EEEEEE;\n  margin-top: 20px;\n\n  p {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 24px;\n    line-height: 33px;\n    color: #0A2540;\n  }\n"])));
export var SubTitle = styled.h4(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  padding: 0.6rem 0 15px 0;\n  font-size: 16px;\n  color: #BDBDBD;\n"])));
export var SubTitleBold = styled(SubTitle)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-family: 'Open Sans';\n  color: #0A2540;\n"])));
export var TextSummary = styled.p(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  line-height: 1.6;\n  font-size: ", ";\n"])), function (props) {
  return props.theme.typography.h4FontSize;
});
export var ErrorMessages = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  margin-top: 30px;\n"])));