import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  display: inline-block;\n"])));
export var Content = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  box-shadow: ", ";\n  background-color: ", ";\n\n  position: absolute;\n  left: auto;\n  right: 0;\n\n  ul {\n    margin: 0;\n    list-style-type: none;\n    padding: 1rem;\n    display: flex;\n    flex-direction: column;\n    font-style: normal;\n    font-weight: normal;\n\n    line-height: ", ";\n    align-items: flex-start;\n\n    li {\n      cursor: pointer;\n      padding-bottom: 1.25rem;\n      white-space: nowrap;\n    }\n\n    :last-child {\n      padding-bottom: 0;\n    }\n  }\n"])), function (props) {
  return props.theme.dropdown.boxShadow;
}, function (props) {
  return props.theme.dropdown.backgroundColor;
}, function (props) {
  return props.theme.typography.baseLineHeight;
});