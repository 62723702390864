import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 80%;\n  padding-bottom: 2rem;\n"])));
export var Header = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: 1.5rem;\n"])), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var BottomBorder = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  border-bottom: 1px solid ", ";\n  width: 95%;\n"])), function (props) {
  return props.theme.colors.divider;
});
export var ViewMoreButton = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  padding-bottom: 1.25rem;\n"])));