import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
export var ErrorListener = function ErrorListener() {
  var _this = this;

  _classCallCheck(this, ErrorListener);

  this.addOnErrorListener = function (func) {
    _this.errorListeners.push(func);
  };

  this.removeOnErrorListener = function (func) {
    _this.errorListeners = _this.errorListeners.filter(function (errorListenersFunc) {
      return func !== errorListenersFunc;
    });
  };

  this.fireError = function (error, type) {
    _this.errorListeners.forEach(function (errorListenersFunc) {
      errorListenersFunc(error, type);
    });
  };

  this.errorListeners = [];
};