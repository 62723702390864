import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { isEqual } from "apollo-utilities";
import React from "react";
import { useSaleorClient } from "./helpers";
import { useAuth } from "./hooks";

var useQuery = function useQuery(query) {
  var variables = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var saleor = useSaleorClient();
  var didMountRef = React.useRef(false);
  var prevDataRef = React.useRef(null);
  var prevUnsubRef = React.useRef(null);

  var _useAuth = useAuth(),
      authenticated = _useAuth.authenticated;

  var _React$useState = React.useState({
    data: null,
    error: null,
    loading: true
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      result = _React$useState2[0],
      setResult = _React$useState2[1];

  var setData = React.useCallback(function (data, loading) {
    if (!isEqual(data, prevDataRef.current)) {
      prevDataRef.current = data;
      setResult({
        data: data,
        error: null,
        loading: false
      });
    } else {
      setResult(function (previousResult) {
        return _objectSpread(_objectSpread({}, previousResult), {}, {
          loading: !!loading
        });
      });
    }
  }, []);

  var _React$useMemo = React.useMemo(function () {
    return saleor.legacyAPIProxy[query](variables, _objectSpread(_objectSpread({}, options), {}, {
      onError: function onError(error) {
        return setResult(function (previousResult) {
          return _objectSpread(_objectSpread({}, previousResult), {}, {
            error: error,
            loading: false
          });
        });
      },
      onUpdate: function onUpdate(data, loading) {
        setData(data, loading);
      }
    }));
  }, [query, options.skip, authenticated]),
      unsubscribe = _React$useMemo.unsubscribe,
      setOptions = _React$useMemo.setOptions,
      _refetch = _React$useMemo.refetch,
      _loadMore = _React$useMemo.loadMore;

  var refetch = React.useCallback(function (refetchVariables) {
    setResult({
      data: null,
      error: null,
      loading: true
    });

    _refetch(refetchVariables);
  }, [query]);
  var loadMore = React.useCallback(function (loadMoreVariables) {
    var mergeResults = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    if (_loadMore) {
      setResult(function (previousResult) {
        return _objectSpread(_objectSpread({}, previousResult), {}, {
          error: null,
          loading: true
        });
      });

      _loadMore(loadMoreVariables, mergeResults);
    }
  }, [query]);
  React.useEffect(function () {
    // skip on initial mount
    if (didMountRef.current) {
      refetch(variables);
    } else {
      didMountRef.current = true;
    }
  }, [JSON.stringify(variables)]); // unsubscribe from watcher on dismount

  React.useEffect(function () {
    if (prevUnsubRef.current) {
      prevUnsubRef.current();
    }

    prevUnsubRef.current = unsubscribe;
    return function () {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [options.skip, authenticated]);
  return _objectSpread(_objectSpread({}, result), {}, {
    loadMore: loadMore,
    refetch: refetch,
    setOptions: setOptions
  });
};

export var queryWithVariablesFactory = function queryWithVariablesFactory(query) {
  return function (variables, options) {
    return useQuery(query, variables, options);
  };
};
export var queryFactory = function queryFactory(query) {
  return function (options) {
    return useQuery(query, undefined, options);
  };
};