import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import { styled } from "@styles";
export var activeLabelStyles = function activeLabelStyles(theme, labelBackground) {
  return "\n  left: 0.5rem;\n  padding: 0 0.5rem;\n  background-color: ".concat(labelBackground, ";\n  font-size: ").concat(theme.input.labelFontSize, ";\n  top: 0;\n");
};

var labelStyles = function labelStyles(theme) {
  return "\n  left: 1rem;\n  padding: 0 0rem;\n  background-color: transparent;\n  font-size: ".concat(theme.typography.baseFontSize, ";\n  top: 50%;\n");
};

export var Label = styled.label(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  ", ";\n  transform: translateY(-50%);\n  transition: all 0.3s ease, color 0s;\n  pointer-events: none;\n"])), function (props) {
  return props.active ? activeLabelStyles(props.theme, props.labelBackground) : labelStyles(props.theme);
});