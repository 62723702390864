import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 30px 0 100px 0;\n  ", "\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin: 0 0 100px;\n  "]))));
export var Container = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 1140px;\n  max-width: 100vw;\n  margin: 0 auto;\n  padding: 90px 1rem 0;\n\n  ", "\n\n  ", "\n"])), media.mediumScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    width: 100%;\n  "]))), media.smallScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    background: #fff;\n    width: 100%;\n    padding: 0 1rem;\n  "]))));
export var Breadcrumbs = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
export var Title = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  margin-top: 80px;\n  margin-bottom: 60px;\n\n  h1 {\n    font-family: Nunito Sans;\n    font-style: normal;\n    font-weight: 800;\n    font-size: 24px;\n    line-height: 33px;\n    color: #000000;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    margin: 70px 0 40px;\n  "]))));
export var Card = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  background: #FFFFFF;\n  border: 1px solid #D4D4D4;\n  box-sizing: border-box;\n  border-radius: 4px;\n  padding: 20px;\n  \n  ", "\n"])), media.smallScreen(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    border: none;\n    bottom: 0;\n  "]))));
export var Row = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-flow: row wrap;\n  margin: 0 -15px;\n"])));
export var CardProduct = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  flex: 0 0 70%;\n  width: 70%;\n  padding: 0 15px;\n\n  ", "\n"])), media.smallScreen(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    flex: 0 0 100%;\n    width: 100%;\n  "]))));
export var CardSummary = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  flex: 0 0 30%;\n  width: 30%;\n  padding: 0 15px;\n\n  ", "\n"])), media.smallScreen(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    flex: 0 0 100%;\n    width: 100%;\n    width: 100%; \n    position: fixed;\n    width: 100%;\n    bottom: 0;\n    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);\n    border-radius: 8px 8px 0px 0px;\n    padding: 0;\n    z-index: 3;\n  "]))));
export var CartHeader = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  ", ";\n"])), media.mediumScreen(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    display: none;\n  "]))));
export var CartFooter = styled.div(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral([""])));
export var Cart = styled.div(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n"])));
export var ProceedButton = styled.div(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  text-align: right;\n  margin-top: 40px;\n"])));