import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
export var NamedObservable = function NamedObservable() {
  var _this = this;

  _classCallCheck(this, NamedObservable);

  this.subscribeToChange = function (name, func) {
    _this.observers.push({
      func: func,
      name: name
    });
  };

  this.unsubscribeToChange = function (name, func) {
    _this.observers = _this.observers.filter(function (observer) {
      return name !== observer.name && func !== observer.func;
    });
  };

  this.subscribeToNotifiedChanges = function (func) {
    _this.notifiedObservers.push(func);
  };

  this.unsubscribeToNotifiedChanges = function (func) {
    _this.notifiedObservers = _this.notifiedObservers.filter(function (notifiedObserverFunc) {
      return func !== notifiedObserverFunc;
    });
  };

  this.notifyChange = function (name, data) {
    _this.observers.forEach(function (observer) {
      if (name === observer.name) {
        observer.func(data);
      }
    });

    _this.notifiedObservers.forEach(function (notifiedObserverFunc) {
      notifiedObserverFunc(data);
    });
  };

  this.observers = [];
  this.notifiedObservers = [];
};