import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16;

import { media, styled } from "@styles";
export var NAVBAR_HEIGHT = "3.55rem";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n  display: flex;\n  justify-content: space-between;\n  height: ", ";\n  position: relative;\n"])), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.light;
}, NAVBAR_HEIGHT);
export var Tile = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  flex-basis: calc(50% - 3rem);\n  overflow: hidden;\n\n  ", "\n"])), media.largeScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    flex-basis: calc(50% - 2rem);\n  "]))));
export var Navigation = styled(Tile)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var Center = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n"])));
export var Actions = styled(Tile)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  justify-content: flex-end;\n"])));
export var LogoWrapper = styled.img(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  line-height: 0;\n\n  ", "\n\n  ", "\n"])), media.largeScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    width: 4rem;\n  "]))), media.largeScreen(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    height: 30px;\n  "]))));
export var IconWrapper = styled.button(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  margin: 0 ", ";\n\n  path {\n    transition: 0.3s;\n  }\n\n  &:hover {\n    path {\n      fill: ", ";\n    }\n  }\n"])), function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.colors.primary;
});
export var SearchButton = styled.button(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  border-left: 1px solid ", ";\n  display: flex;\n  height: 100%;\n  align-items: center;\n  padding: 0 ", ";\n  transition: all 0.3s;\n\n  path {\n    transition: 0.3s;\n  }\n\n  &:hover {\n    color: ", ";\n    path {\n      fill: ", ";\n    }\n  }\n"])), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.primary;
});
export var Text = styled.span(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-right: 2rem;\n"])), function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Mobile = styled.ul(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  padding-left: 1rem;\n\n  li {\n    line-height: 0;\n  }\n"])));
export var Desktop = styled.ul(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  display: flex;\n  padding: 0;\n  white-space: nowrap;\n\n  li {\n    margin-left: 2rem;\n  }\n"])));
export var Button = styled.button(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-transform: uppercase;\n\n  &:hover {\n    color: ", ";\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.typography.baseFontSize;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.typography.boldFontWeight;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.primary;
});
export var NestedLink = styled.button(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral([""])));