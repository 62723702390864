export function sortCheckoutLines(a, b) {
  var _a$variant$id, _b$variant$id;

  if (a.id && b.id) {
    var _a$id, _b$id;

    var _aId = ((_a$id = a.id) === null || _a$id === void 0 ? void 0 : _a$id.toUpperCase()) || "";

    var _bId = ((_b$id = b.id) === null || _b$id === void 0 ? void 0 : _b$id.toUpperCase()) || "";

    return _aId < _bId ? -1 : _aId > _bId ? 1 : 0;
  }

  var aId = ((_a$variant$id = a.variant.id) === null || _a$variant$id === void 0 ? void 0 : _a$variant$id.toUpperCase()) + JSON.stringify(a.appliedAddon) || "";
  var bId = ((_b$variant$id = b.variant.id) === null || _b$variant$id === void 0 ? void 0 : _b$variant$id.toUpperCase()) + JSON.stringify(b.appliedAddon) || "";
  return aId < bId ? -1 : aId > bId ? 1 : 0;
}