import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: ", ";\n"])), function (props) {
  return props.theme.spacing.fieldSpacer;
});
export var Indicator = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  right: 1rem;\n  transition-duration: 0.3s;\n  transform: ", ";\n"])), function (props) {
  return props.rotate === "true" ? "rotate(180deg)" : "rotate(0deg)";
});
export var HelpText = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n"])), function (props) {
  return props.theme.input.labelColor;
}, function (props) {
  return props.theme.input.labelFontSize;
});
export var ErrorMessages = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  top: 100%;\n"])));