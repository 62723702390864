import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as AttributesList from "./attributes";
import * as Collections from "./collections";
import * as Orders from "./orders";
import * as Product from "./products";
import * as Shop from "./shop";
// TODO: Add ability to pass custom fragments to queries
export var QUERIES = {
  Attributes: function Attributes(client, options) {
    return client.watchQuery(_objectSpread({
      query: AttributesList.attributes
    }, options));
  },
  CollectionList: function CollectionList(client, options) {
    return client.watchQuery(_objectSpread({
      query: Collections.collections
    }, options));
  },
  GetShopDetails: function GetShopDetails(client, options) {
    return client.watchQuery(_objectSpread({
      query: Shop.getShop
    }, options));
  },
  OrderDetails: function OrderDetails(client, options) {
    return client.watchQuery(_objectSpread({
      query: Orders.orderDetailsByTokenQuery
    }, options));
  },
  OrderDetailsByUser: function OrderDetailsByUser(client, options) {
    return client.watchQuery(_objectSpread({
      query: Orders.userOrderDetailsByTokenQuery
    }, options));
  },
  OrdersByUser: function OrdersByUser(client, options) {
    return client.watchQuery(_objectSpread({
      query: Orders.ordersByUser
    }, options));
  },
  VariantsProducts: function VariantsProducts(client, options) {
    return client.watchQuery(_objectSpread({
      query: Product.variantsProducts
    }, options));
  }
};