import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-areas: \"sidebar preview\";\n  height: 100%;\n  grid-template-columns: 76px 1fr;\n  grid-column-gap: 40px;\n"])));
export var Thumbnail = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 76px;\n  display: flex;\n  border-width: 4px;\n  border-style: solid;\n  border-color: ", ";\n  justify-content: center;\n  height: 100px;\n  overflow: hidden;\n  img {\n    width: 100%;\n    object-fit: contain;\n  }\n\n  margin-top: 20px;\n  margin-bottom: 20px;\n"])), function (props) {
  return props.activeThumbnail === true ? props.theme.colors.thumbnailBorder : "transparent";
});
export var Button = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  height: 50px;\n  width: 100%;\n  position: absolute;\n  z-index: 1;\n  background-color: rgba(50, 50, 50, 0.3);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n"])));
export var TopButton = styled(Button)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  top: 0%;\n\n  transform: rotate(180deg);\n"])));
export var BottomButton = styled(Button)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  bottom: 0%;\n"])));
export var ThumbnailsContainer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  position: relative;\n"])));
export var ThumbnailList = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  position: relative;\n  height: 100%;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  scrollbar-width: none;\n  ::-webkit-scrollbar {\n    width: 0px;\n  }\n\n  ul {\n    position: absolute;\n    display: block;\n    padding: 0;\n    margin: 0;\n  }\n"])));
export var Preview = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  grid-area: preview;\n  width: auto;\n  max-height: 560px;\n  overflow: hidden;\n  img {\n    width: 100%;\n    object-fit: contain;\n  }\n"])));