import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { register, unregister } from "register-service-worker";
export var useServiceWorker = function useServiceWorker(_ref) {
  var _ref$timeout = _ref.timeout,
      timeout = _ref$timeout === void 0 ? 1000 : _ref$timeout;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      updateAvailable = _React$useState2[0],
      setUpdateAvailable = _React$useState2[1];

  var _React$useState3 = React.useState(null),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      registration = _React$useState4[0],
      setRegistration = _React$useState4[1];

  React.useEffect(function () {
    var interval = setInterval(function () {
      if (registration) {
        registration.update();
      }
    }, timeout);
    return function () {
      return clearInterval(interval);
    };
  }, [registration]);

  var registered = function registered(registration) {
    return setRegistration(registration);
  };

  var updated = function updated() {
    return setUpdateAvailable(true);
  };

  var error = function error(err) {
    return console.log('Error service worker', err);
  };

  React.useEffect(function () {
    if (window.Cypress || !process.env.SERVICE_WORKER_EXISTS) {
      unregister();
    } else {
      register("/service-worker.js", {
        registered: registered,
        updated: updated,
        error: error
      });
      return function () {
        return unregister();
      };
    }
  }, []);
  return {
    updateAvailable: updateAvailable
  };
};