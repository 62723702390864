import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

import gql from "graphql-tag";
import { checkoutFragment } from "../fragments/checkout";
import { paymentFragment } from "../fragments/payment";
import { orderDetailFragment } from "../fragments/order";
import { checkoutErrorFragment, paymentErrorFragment } from "../fragments/errors";
export var updateCheckoutLineMutation = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation UpdateCheckoutLine($checkoutId: ID!, $lines: [CheckoutLineInput]!) {\n    checkoutLinesUpdate(checkoutId: $checkoutId, lines: $lines) {\n      checkout {\n        ...Checkout\n      }\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n    }\n  }\n"])), checkoutFragment, checkoutErrorFragment);
export var createCheckoutMutation = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation CreateCheckout($checkoutInput: CheckoutCreateInput!) {\n    checkoutCreate(input: $checkoutInput) {\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n      checkout {\n        ...Checkout\n      }\n    }\n  }\n"])), checkoutFragment, checkoutErrorFragment);
export var updateCheckoutBillingAddressWithEmailMutation = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation UpdateCheckoutBillingAddressWithEmail(\n    $checkoutId: ID!\n    $billingAddress: AddressInput!\n    $email: String!\n  ) {\n    checkoutBillingAddressUpdate(\n      checkoutId: $checkoutId\n      billingAddress: $billingAddress\n    ) {\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n      checkout {\n        ...Checkout\n      }\n    }\n    checkoutEmailUpdate(checkoutId: $checkoutId, email: $email) {\n      checkout {\n        ...Checkout\n      }\n      errors: checkoutErrors {\n        code\n        field\n        message\n      }\n    }\n  }\n"])), checkoutFragment, checkoutErrorFragment);
export var updateCheckoutBillingAddressMutation = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation UpdateCheckoutBillingAddress(\n    $checkoutId: ID!\n    $billingAddress: AddressInput!\n  ) {\n    checkoutBillingAddressUpdate(\n      checkoutId: $checkoutId\n      billingAddress: $billingAddress\n    ) {\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n      checkout {\n        ...Checkout\n      }\n    }\n  }\n"])), checkoutFragment, checkoutErrorFragment);
export var updateCheckoutShippingAddressMutation = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation UpdateCheckoutShippingAddress(\n    $checkoutId: ID!\n    $shippingAddress: AddressInput!\n    $email: String!\n  ) {\n    checkoutShippingAddressUpdate(\n      checkoutId: $checkoutId\n      shippingAddress: $shippingAddress\n    ) {\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n      checkout {\n        ...Checkout\n      }\n    }\n    checkoutEmailUpdate(checkoutId: $checkoutId, email: $email) {\n      checkout {\n        ...Checkout\n      }\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n    }\n  }\n"])), checkoutFragment, checkoutErrorFragment);
export var updateCheckoutShippingMethodMutation = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation UpdateCheckoutShippingMethod(\n    $checkoutId: ID!\n    $shippingMethodId: ID!\n  ) {\n    checkoutShippingMethodUpdate(\n      checkoutId: $checkoutId\n      shippingMethodId: $shippingMethodId\n    ) {\n      checkout {\n        ...Checkout\n      }\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n    }\n  }\n"])), checkoutFragment, checkoutErrorFragment);
export var addCheckoutPromoCode = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation AddCheckoutPromoCode($checkoutId: ID!, $promoCode: String!) {\n    checkoutAddPromoCode(checkoutId: $checkoutId, promoCode: $promoCode) {\n      checkout {\n        ...Checkout\n      }\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n    }\n  }\n"])), checkoutFragment, checkoutErrorFragment);
export var removeCheckoutPromoCode = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation RemoveCheckoutPromoCode($checkoutId: ID!, $promoCode: String!) {\n    checkoutRemovePromoCode(checkoutId: $checkoutId, promoCode: $promoCode) {\n      checkout {\n        ...Checkout\n      }\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n    }\n  }\n"])), checkoutFragment, checkoutErrorFragment);
export var createCheckoutPaymentMutation = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  mutation CreateCheckoutPayment(\n    $checkoutId: ID!\n    $paymentInput: PaymentInput!\n  ) {\n    checkoutPaymentCreate(checkoutId: $checkoutId, input: $paymentInput) {\n      checkout {\n        ...Checkout\n      }\n      payment {\n        ...Payment\n      }\n      errors: paymentErrors {\n        ...PaymentError\n      }\n    }\n  }\n"])), checkoutFragment, paymentFragment, paymentErrorFragment);
export var completeCheckoutMutation = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation CompleteCheckout(\n    $checkoutId: ID!\n    $paymentData: JSONString\n    $redirectUrl: String\n    $storeSource: Boolean,\n    $transactionFee: Decimal,\n  ) {\n    checkoutComplete(\n      checkoutId: $checkoutId\n      paymentData: $paymentData\n      redirectUrl: $redirectUrl\n      storeSource: $storeSource\n      transactionFee: $transactionFee\n    ) {\n      errors: checkoutErrors {\n        ...CheckoutError\n      }\n      order {\n        ...OrderDetail\n      }\n      confirmationNeeded\n      confirmationData\n    }\n  }\n"])), orderDetailFragment, checkoutErrorFragment);