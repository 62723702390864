import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { NavLink } from "@components/atoms";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  background-color: ", ";\n  position: relative;\n  padding: 20px 40px;\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
});
export var Rows = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  width: 80%;\n"])));
export var Side = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: flex-end;\n  width: 20%;\n"])));
export var RowItem = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  padding: 20px 40px;\n"])));
export var SubLink = styled(NavLink)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  text-transform: capitalize;\n  padding-bottom: 20px;\n  ", "\n"])), function (_ref2) {
  var light = _ref2.light,
      theme = _ref2.theme;
  return light && "\n    color: ".concat(theme.colors.lightFont, ";\n    font-size: ").concat(theme.typography.smallFontSize, ";\n  ");
});