import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-flow: row wrap;\n  margin-bottom: 25px;\n\n  ", "\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin: 0;\n    padding: 20px 0;\n    border-bottom: 1px solid #EDEDED;\n  "]))));
export var CheckboxContainer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 5%;\n\n  ", "\n"])), media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    width: 10%;\n  "]))));
export var QuantityButtons = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0 15px 0 0;\n  width: 50px;\n\n  > div {\n    display: flex;\n  }\n\n  svg {\n    cursor: pointer;\n    justify-self: center;\n  }\n"])));
export var Photo = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  grid-area: photo;\n  display: flex;\n  align-items: flex-start;\n  align-self: top;\n  width: 10%;\n  height: 80px;\n  align-items: center;\n  border: 1px solid #F1F1F1;\n  background: #fff;\n\n\n  ", "\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n\n  a {\n    display: flex;\n  }\n"])), media.smallScreen(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    width: 20%;\n  "]))));
export var Description = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  grid-area: description;\n  height: 100%;\n  width: 45%;\n  padding: 0 10px;\n  ", "\n"])), media.mediumScreen(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    margin-left: 0px;\n  "]))));
export var Addons = styled.p(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  margin: 6px 0;\n  text-align: left;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 15px;\n  color: #787878;\n"])));
export var Attributes = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-columns: max-content;\n  grid-template-columns: repeat(auto-fit, minmax(166px, 500px));\n  margin-left: -15px;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 15px;\n  color: #787878;\n  \n  ", ";\n"])), media.mediumScreen(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    flex-flow: column;\n  "]))));
export var SingleAttribute = styled.p(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  margin: 0;\n  flex-grow: 1;\n  display: flex;\n  justify-content: flex-start;\n  white-space: nowrap;\n  background-color: white;\n  padding: 0px 15px;\n"])));
export var Name = styled.p(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  font-size: ", ";\n  text-align: left;\n  margin-bottom: 6px;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 14px;\n  line-height: 19px;\n  color: #0A2540;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h4FontSize;
});
export var LightFont = styled.span(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: rgba(125, 125, 125, 0.6);\n"])), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Price = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  display: flex;\n  justify-content: center;\n  font-weight: bold;\n  ", "\n\n  p {\n    margin: 0;\n  }\n"])), function (props) {
  return props.theme.typography.h4FontSize;
}, media.mediumScreen(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    font-weight: normal;\n    flex-direction: column;\n  "]))));
export var PriceLabel = styled.p(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  display: none;\n  ", "\n"])), media.mediumScreen(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n    display: block;\n  "]))));
export var TotalPrice = styled(Price)(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  ", "\n"])), media.mediumScreen(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n    p {\n      text-align: right;\n    }\n  "]))));
export var Trash = styled.div(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n\n  ", "\n"])), media.smallScreen(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n    width: 60%;\n    justify-content: flex-end;\n  "]))));
export var UnitPrice = styled(Price)(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n  grid-area: unitPrice;\n  width: 20%;\n  padding: 0 10px;\n  height: 100%;\n\n  p {\n    text-align: right;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 14px;\n    line-height: 19px;\n    text-align: right;\n    color: #0A2540;\n\n    ", "\n  }\n"])), media.smallScreen(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n      font-size: 14px;\n      font-weight: bold;\n    "]))));
export var Quantity = styled.div(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  width: 20%;\n  padding: 0 10px;\n\n  ", "\n"])), media.smallScreen(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["\n    width: 40%;\n\n    > div {\n      margin-bottom: 0;\n    }\n  "]))));