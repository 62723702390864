import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 1.4rem;\n"])));
export var Bar = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  height: 5rem;\n  // background-color: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  // padding: 0 2rem;\n  font-size: ", ";\n  margin-top: 1rem;\n  margin-bottom: 1.4rem;\n"])), function (props) {
  return props.theme.tile.backgroundColor;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var LeftSide = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n\n  h5 {\n    font-family: Nunito Sans;\n    font-style: normal;\n    font-weight: 900;\n    font-size: 35px;\n    line-height: 48px;\n    /* identical to box height */\n    display: flex;\n\n    img {\n      width: 40px;\n      border-radius: 90px;\n      padding: 5px;\n      height: 40px;\n      margin-right: 10px;\n    }\n  }\n"])));
export var RightSide = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  height: 1.2rem;\n"])));
export var FiltersButton = styled.button(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"])), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Clear = styled.button(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  padding-left: 2rem;\n  cursor: pointer;\n  font-size: ", ";\n  color: ", ";\n"])), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.colors.lightFont;
});
export var Element = styled.span(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  padding-left: 2rem;\n"])));
export var ElementSort = styled.span(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  background: #fff;\n  border: 1px solid #a7a7a7;\n  padding: 10px 10px;\n  margin-left: 20px;\n"])));
export var Filters = styled.span(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0.6rem;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Label = styled.span(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 19px;\n  color: #000000;\n"])));
export var Sort = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  width: 280px;\n  display: inline-block;\n\n  ", "\n"])), media.smallScreen(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    width: 146px;\n  "]))));
export var SortContainer = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  display: flex;\n"])));
export var FiltersChipsWrapper = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  > div {\n    margin: 0.4rem;\n  }\n"])));