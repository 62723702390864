import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { media, styled } from "@styles";
export var AddressForm = styled.form(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n"])));
export var RowWithTwoCells = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  & > div {\n    width: calc(50% - ", " / 2);\n    ", "\n  }\n"])), function (props) {
  return props.theme.spacing.fieldSpacer;
}, media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      width: 100%;\n    "]))));
export var RowWithOneCell = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 100%;\n"])));