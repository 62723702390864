import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { defaultConfig } from "../config";
import { LocalStorageManager } from "../data";
import { ApolloClientManager } from "../data/ApolloClientManager";
import { LocalStorageHandler } from "../helpers/LocalStorageHandler";
import { JobsManager } from "../jobs";
import { SaleorState } from "../state";
import { AuthAPI } from "./Auth";
import { SaleorCartAPI } from "./Cart";
import { SaleorCheckoutAPI } from "./Checkout";
import { CollectionsAPI } from "./collections/collections";
import { CategoriesAPI } from "./categories/categories";
import { ProductsAPI } from "./products/products";
export * from "./Checkout";
export * from "./Cart";
export var SaleorAPI =
/**
 * @deprecated Please do not use it anymore. Reference to API Proxy will be removed in future.
 * Now it just exists for legacy React hooks, which also will be removed.
 */
function SaleorAPI(client, apiProxy, config, onStateUpdate) {
  _classCallCheck(this, SaleorAPI);

  this.legacyAPIProxy = apiProxy;

  var finalConfig = _objectSpread(_objectSpread(_objectSpread({}, defaultConfig), config), {}, {
    loadOnStart: _objectSpread(_objectSpread({}, defaultConfig.loadOnStart), config === null || config === void 0 ? void 0 : config.loadOnStart)
  });

  var localStorageHandler = new LocalStorageHandler();
  var apolloClientManager = new ApolloClientManager(client);
  var jobsManager = new JobsManager(localStorageHandler, apolloClientManager);
  var saleorState = new SaleorState(finalConfig, localStorageHandler, apolloClientManager, jobsManager);
  var localStorageManager = new LocalStorageManager(localStorageHandler, saleorState);

  if (onStateUpdate) {
    saleorState.subscribeToNotifiedChanges(onStateUpdate);
  }

  this.auth = new AuthAPI(saleorState, jobsManager, finalConfig);
  this.checkout = new SaleorCheckoutAPI(saleorState, jobsManager);
  this.cart = new SaleorCartAPI(localStorageManager, apolloClientManager, saleorState, jobsManager);
  this.categories = new CategoriesAPI(client);
  this.collections = new CollectionsAPI(client);
  this.products = new ProductsAPI(client);
  this.legacyAPIProxy.attachAuthListener(function (authenticated) {
    if (!authenticated) {
      localStorageHandler.setCheckout({});
      localStorageHandler.setPayment({});
      localStorageHandler.setJobs(null);
    }
  });
};