import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

import { media, styled } from "@styles";
export var Loader = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 10;\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin: 45px 0;\n  display: grid;\n\n  grid-template-columns: 8fr 4fr;\n  grid-template-rows: 85px auto auto;\n  grid-column-gap: 30px;\n  grid-template-areas:\n    \"navigation cartSummary\"\n    \"checkout cartSummary\"\n    \"paymentGateways cartSummary\"\n    \"button cartSummary\";\n\n  ", "\n\n  ", "\n"])), media.mediumScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      \"navigation\"\n      \"checkout\"\n      \"paymentGateways\"\n      \"button\";\n  "]))), media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin: 140px 0;\n  "]))));
export var WrapperSection = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n"])));
export var Card = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  background: #FFFFFF;\n  border: 1px solid #D4D4D4;\n  box-sizing: border-box;\n  border-radius: 4px;\n  padding: 33px 22px;\n"])));
export var Navigation = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  grid-area: navigation;\n  padding-bottom: 43px;\n  height: 85px;\n"])));
export var Checkout = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  grid-area: checkout;\n  // padding: 3rem 0 0 0;\n"])));
export var PaymentGateways = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", "\n  grid-area: paymentGateways;\n"])), function (props) {
  return props.hide && "display: none;";
});
export var CartSummary = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  grid-area: cartSummary;\n\n  ", "\n"])), media.mediumScreen(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    position: fixed;\n    bottom: 0;\n  "]))));
export var Button = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  grid-area: button;\n  margin: 3rem 0 0 0;\n"])));