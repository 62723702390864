import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27, _templateObject28, _templateObject29, _templateObject30, _templateObject31, _templateObject32, _templateObject33;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin: 1rem;\n  background: #fff;\n"])));
export var Title = styled.h5(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 34px;\n  color: #000000;\n  margin-bottom: 20px;\n"])));
export var TitleDetail = styled(Title)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-weight: 800;\n  font-family: Nunito Sans;\n\n  button {\n    float: right;\n    margin-top: 5px;\n  }\n"])));
export var TableRefundContainer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  overflow: auto;\n"])));
export var TableRefund = styled.table(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  margin: 0;\n\n  & > thead {\n    tr {\n      th {\n        background: #F5F5F5;\n        padding: 15px 10px;\n        font-family: Open Sans;\n        font-style: normal;\n        font-weight: normal;\n        font-size: 0.8rem;\n        line-height: 22px;\n        align-items: center;\n        color: #000000;\n\n        &:first-child {\n          border-radius: 10px 0 0 10px;\n        }\n\n        &:last-child {\n          border-radius: 0 10px 10px 0;\n        }\n      }\n    }\n  }\n\n  & > tbody {\n    tr {\n      td {\n        padding: 15px 10px;\n        font-family: Open Sans;\n        font-style: normal;\n        font-weight: normal;\n        font-size: 0.8rem;\n        line-height: 22px;\n        align-items: center;\n        color: #000000;\n        vertical-align: top;\n        \n        p {\n          white-space: nowrap;\n          overflow: hidden;\n          text-overflow: ellipsis;\n          max-width: 100%;\n        }\n      }\n    }\n  }\n"])));
export var Warning = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: red;\n  font-size: 12px;\n  margin-top: 10px;\n  position: relative;\n  top: -20px;\n"])));
export var WarningCancel = styled(Warning)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  top: 0;\n  margin-top: -20px;\n"])));
export var SubTitle = styled(TitleDetail)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-size: 20px;\n  margin-top:30px;\n"])));
export var CardShipping = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  padding: 26px 25px;\n  border: 1px solid #D8D8D8;\n  box-sizing: border-box;\n  border-radius: 8px;\n\n  h3 {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 25px;\n    color: #000000;\n  }\n\n  span {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 22px;\n\n    color: #787878;\n  }\n"])));
export var CardStatus = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  padding: 20px 40px;\n  background: #F5F5F5;\n  border-radius: 8px;\n  border-radius: 8px;\n\n  ", "\n  \n  table {\n    margin: 0;\n\n    &:last-child {\n      margin: 20px 0 0;\n      \n      ", "\n    }\n\n    tr {\n      border: none;\n      height: 24px;\n\n      th {\n        font-family: Open Sans;\n        font-style: normal;\n        font-weight: normal;\n        font-size: 14px;\n        line-height: 19px;\n        color: #000000;\n        padding: 0;\n\n        &:first-child {\n          width: 30%;\n\n          ", "\n        }\n      }\n\n      td {\n        font-family: Open Sans;\n        font-style: normal;\n        font-weight: bold;\n        font-size: 14px;\n        line-height: 19px;\n        color: #0A2540;\n        padding: 0;\n        vertical-align: top;\n\n        button {\n          font-family: Open Sans;\n          font-style: normal;\n          font-weight: bold;\n          font-size: 14px;\n          line-height: 19px;\n          color: #0A2540;\n          padding: 0;\n          background: none;\n          border: none;\n        }\n      }\n    }\n  }\n"])), media.smallScreen(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    padding: 20px 20px;\n  "]))), media.smallScreen(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n        margin: 0;\n      "]))), media.smallScreen(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n            width: 50%;\n          "]))));
export var PaymentMethod = styled(CardStatus)(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  margin-bottom: 30px;\n\n  table {\n    tr {\n      th {\n        &:first-child {\n          font-family: Open Sans;\n          font-style: normal;\n          font-weight: normal;\n          font-size: 14px;\n          line-height: 19px;\n          color: #000000;\n        }\n\n        &:last-child {\n          font-family: Open Sans;\n          font-style: normal;\n          font-weight: bold;\n          font-size: 14px;\n          line-height: 19px;\n          color: #0A2540;\n          text-align: left;\n        }\n\n        ", "\n      }\n\n      td {\n        &:first-child {\n          font-family: Open Sans;\n          font-style: normal;\n          font-weight: normal;\n          font-size: 14px;\n          line-height: 19px;\n          color: #000000;\n        }\n\n        &:last-child {\n          font-family: Open Sans;\n          font-style: normal;\n          font-weight: bold;\n          font-size: 14px;\n          line-height: 19px;\n          color: #0A2540;\n          text-align: left;\n        }\n        ", "\n      }\n    }\n  }\n"])), media.smallScreen(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n          padding-bottom: 10px;\n        "]))), media.smallScreen(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n          padding-bottom: 10px;\n        "]))));
export var HeadTable = styled.div(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  margin-top: 30px;\n  background: #F5F5F5;\n  border-radius: 8px;\n  padding: 15px 25px;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 22px;\n  display: flex;\n  align-items: center;\n  color: #000000;\n\n  .left {\n    width : 70%;\n  }\n\n  .mid {\n    width: 10%;\n  }\n\n  .right {\n    width: 20%;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n    padding: 15px 0;\n    background: none;\n    border-radius: 0;\n    border-bottom: 1px solid #DCDCDC; \n\n    .right {\n      text-align: right;\n    }\n  "]))));
export var BodyTable = styled.div(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  font-weight: 800;\n  font-family: Nunito Sans;\n  padding: 15px 25px;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 22px;\n  display: flex;\n  align-items: center;\n  color: #000000;\n  border-bottom: 0.5px solid #DCDCDC;\n\n  .left {\n    width : 70%;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 15px;\n    line-height: 20px;\n    color: #484848;\n  }\n\n  .mid {\n    width: 10%;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 15px;\n    line-height: 20px;\n    color: #000000;\n  }\n\n  .right {\n    width: 20%;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 15px;\n    line-height: 20px;\n    color: #000000;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n    padding: 15px 0;\n\n    .right {\n      text-align: right;\n    }\n  "]))));
export var FlexItem = styled.div(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  padding: 5px 25px;\n  position: relative;\n\n  ", "\n"])), media.smallScreen(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n    padding: 5px 0;\n  "]))));
export var Summary = styled.div(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n  margin-top: 25px;\n  margin-bottom: 25px;\n"])));
export var SubTotal = styled(FlexItem)(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n  label {\n    width: 80%;\n    color: #484848;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 15px;\n    line-height: 20px;\n    color: #484848;\n  }\n\n  span {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 15px;\n    line-height: 20px;\n    text-align: left;\n    color: #484848;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n    label {\n      width: 50%;\n    }\n    \n    & > span {\n      position: absolute;\n      right: 0;\n    }\n  "]))));
export var InfoTotal = styled(FlexItem)(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["\n  label {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 15px;\n    line-height: 20px;\n    color: #484848\n    width: 80%;\n  }\n\n  span {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 15px;\n    line-height: 20px;\n    text-align: left;\n    color: #484848;\n  }\n  ", "\n"])), media.smallScreen(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["\n    label {\n      width: 50%;\n    }\n\n    & > span {\n      position: absolute;\n      right: 0;\n    }\n  "]))));
export var InfoDiscount = styled(InfoTotal)(_templateObject28 || (_templateObject28 = _taggedTemplateLiteral(["\n  label {\n    color: #00AB4E;\n  }\n  \n  span {\n    color: #00AB4E;\n  }\n"])));
export var Total = styled(FlexItem)(_templateObject29 || (_templateObject29 = _taggedTemplateLiteral(["\n  label {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 20px;\n    line-height: 27px;\n    color: #484848;\n    width: 80%;\n  }\n\n  span {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 20px;\n    line-height: 27px;\n    text-align: left;\n    color: #484848;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject30 || (_templateObject30 = _taggedTemplateLiteral(["\n    label {\n      width: 50%;\n    }\n\n    & > span {\n      position: absolute;\n      right: 0;\n    }\n  "]))));
export var Filter = styled.div(_templateObject31 || (_templateObject31 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n  overflow: auto;\n"])));
export var Button = styled.button(_templateObject32 || (_templateObject32 = _taggedTemplateLiteral(["\n  background: none;\n  border: none;\n  display: flex;\n  align-items: center;\n  height: 45px;\n  background: ", ";\n  border: 2px solid ", ";\n  box-sizing: border-box;\n  padding: 14px;\n  border-radius: 10px;\n  margin-right: 20px;\n  font-family: Open Sans;\n  font-style: normal;\n  white-space: nowrap;\n  font-weight: ", ";\n  font-size: 18px;\n  line-height: 25px;\n  display: flex;\n  color: ", "\n\n  img {\n    margin-right: 10px;\n  }\n\n  ", "\n"])), function (props) {
  return props.active ? "#EBFFF3" : "#fff";
}, function (props) {
  return props.active ? "#00AB4E" : "#eee";
}, function (props) {
  return props.active ? "600" : "normal";
}, function (props) {
  return props.active ? "#00AB4E" : "#0A2540";
}, media.smallScreen(_templateObject33 || (_templateObject33 = _taggedTemplateLiteral(["\n    font-size: 12px;\n    padding: 5px;\n    height: 35px;\n\n    img {\n      width: 15px;\n      margin-right: 5px;\n    }\n  "]))));