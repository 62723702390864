import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import gql from "graphql-tag";
import { pageInfo } from "../fragments/pageInfo";
import { baseCategoryFragment, categoryFragment } from "../fragments/categories";
export var categoryList = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query CategoryList($first: Int!, $after: String) {\n    categories(first: $first, after: $after) {\n      edges {\n        node {\n          ...BaseCategory\n        }\n      }\n      pageInfo {\n        ...PageInfo\n      }\n    }\n  }\n"])), baseCategoryFragment, pageInfo);
export var categoryChildrenList = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query CategoryChildrenList($id: ID!, $first: Int!, $after: String) {\n    category(id: $id) {\n      id\n      children(first: $first, after: $after) {\n        edges {\n          node {\n            ...BaseCategory\n          }\n        }\n        pageInfo {\n          ...PageInfo\n        }\n      }\n    }\n  }\n"])), baseCategoryFragment, pageInfo);
export var categoryAncestorsList = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query CategoryAncestorsList($id: ID!, $first: Int!, $after: String) {\n    category(id: $id) {\n      id\n      ancestors(first: $first, after: $after) {\n        edges {\n          node {\n            ...BaseCategory\n          }\n        }\n        pageInfo {\n          ...PageInfo\n        }\n      }\n    }\n  }\n"])), baseCategoryFragment, pageInfo);
export var categoryDetails = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  query CategoryDetails($id: ID, $slug: String) {\n    category(id: $id, slug: $slug) {\n      ...CategoryDetails\n    }\n  }\n"])), categoryFragment);