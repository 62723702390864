import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var AttributeList = styled.ul(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  columns: 2;\n  column-width: 50%;\n\n  ", ";\n  width: 100%;\n  padding: 0;\n  margin: 0;\n\n  li {\n    margin-bottom: 30px;\n    font-size: ", ";\n  }\n\n  li::before {\n    content: \"\u2022\";\n    margin-right: 20px;\n    color: ", ";\n  }\n"])), media.largeScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    column-width: 100%;\n    columns: 1;\n  "]))), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.colors.listBullet;
});
export var Tabs = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: none;\n  width: 100%;\n  border-bottom: 1px solid ", ";\n  margin-bottom: 70px;\n  overflow: hidden;\n"])), function (props) {
  return props.theme.colors.tabsBorder;
});
export var TabTitle = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-family: Nunito Sans;\n  font-style: normal;\n  font-weight: 900;\n  font-size: 25px;\n  line-height: 34px;\n  color: #0A2540;\n  border-bottom: 1px solid #DEDEDE;\n  padding-bottom: 5px;\n\n  & > div {\n    margin-top: 10px;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 160%;\n    color: #7C7C7C;\n  }\n"])));
export var AttributeName = styled.span(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), function (props) {
  return props.theme.colors.listAttributeName;
});