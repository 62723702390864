export var LocalStorageItems;

(function (LocalStorageItems) {
  LocalStorageItems["JOB_QUEUE_CHECKOUT"] = "job_queueCheckout";
  LocalStorageItems["TOKEN"] = "token";
  LocalStorageItems["CSRF_TOKEN"] = "csrf_token";
  LocalStorageItems["CHECKOUT"] = "data_checkout";
  LocalStorageItems["PAYMENT"] = "data_payment";
})(LocalStorageItems || (LocalStorageItems = {}));

export var LocalStorageEvents;

(function (LocalStorageEvents) {
  LocalStorageEvents["CLEAR"] = "clear";
})(LocalStorageEvents || (LocalStorageEvents = {}));