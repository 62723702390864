import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { styled } from "@styles";
import { keyframes } from "styled-components";
import { getContentWindowHeight } from "@utils/styles";
var firstItem = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    left: 0;\n  }\n  12.5% {\n    left: 2rem;\n  }\n  25% {\n    left: 4rem;\n  }\n  37.5% {\n    left: 2rem;\n  }\n  50% {\n    left: 0;\n  }\n  100% {\n    left: 0;\n  }\n"])));
var secondItem = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  0% {\n    left: 2rem;\n  }\n  12.5% {\n    left: 2rem;\n  }\n  25% {\n    left: 4rem;\n  }\n  37.5% {\n    left: 2rem;\n  }\n  62.5% {\n    left: 2rem;\n  }\n  75% {\n    left: 0;\n  }\n  87.5% {\n    left: 2rem;\n  }\n  100% {\n    left: 2rem;\n  }\n"])));
var thirdItem = keyframes(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  0% {\n    left: 4rem;\n  }\n  50% {\n    left: 4rem;\n  }\n  62.5% {\n    left: 2rem;\n  }\n  75% {\n    left: 0;\n  }\n  87.5% {\n    left: 2rem;\n  }\n  100% {\n    left: 4rem;\n  }\n"])));
export var Wrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: ", ";\n  padding: ", " 0;\n"])), function (props) {
  return props.fullScreen ? "".concat(getContentWindowHeight(), "px") : "100%";
}, function (props) {
  return props.theme.spacing.spacer;
});
export var Items = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: relative;\n  width: 5rem;\n  height: 1rem;\n  margin: 0 auto;\n\n  span {\n    background-color: ", ";\n    width: 1rem;\n    height: 1rem;\n    border-radius: 1rem;\n    position: absolute;\n\n    &:nth-child(1) {\n      left: 0;\n      animation: ", " 2s infinite;\n      animation-timing-function: linear;\n    }\n\n    &:nth-child(2) {\n      left: 2rem;\n      animation: ", " 2s infinite;\n      animation-timing-function: linear;\n    }\n\n    &:nth-child(3) {\n      right: 0;\n      animation: ", " 2s infinite;\n      animation-timing-function: linear;\n    }\n  }\n"])), function (props) {
  return props.theme.colors.secondary;
}, firstItem, secondItem, thirdItem);