import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { styled } from "@styles";
export var Checkbox = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-bottom: 1.25rem;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  cursor: pointer;\n  position: relative;\n  margin-left: -4px;\n"])));
export var Label = styled.label(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  cursor: pointer;\n  justify-content: flex-start;\n  align-items: center;\n  padding-right: 1.25rem;\n  input[type=\"checkbox\"] {\n    display: none;\n    position: relative;\n    right: -999em;\n  }\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 26px;\n    width: 26px;\n\n    span {\n      border: 1px solid ", ";\n      width: 14px;\n      height: 14px;\n      display: inline-block;\n    }\n\n    ", ":hover & {\n      border-radius: 50%;\n      border: 1px solid;\n      border-color: ", ";\n      background-color: ", ";\n    }\n\n    :focus {\n      border-radius: 50%;\n      border: 1px solid;\n      outline: none;\n      border-color: ", ";\n      background-color: ", ";\n    }\n  }\n\n  input:checked + div {\n    span {\n      background-clip: content-box;\n      padding: 2px;\n      background-color: ", ";\n    }\n  }\n"])), function (props) {
  return props.theme.colors.secondary;
}, Checkbox, function (props) {
  return props.theme.colors.secondaryOverlay;
}, function (props) {
  return props.theme.colors.secondaryOverlay;
}, function (props) {
  return props.theme.colors.secondaryOverlayDark;
}, function (props) {
  return props.theme.colors.secondaryOverlayDark;
}, function (props) {
  return props.theme.colors.secondary;
});