import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { findValueInEnum } from "./utils";
export var JWTError;

(function (JWTError) {
  JWTError["invalid"] = "InvalidTokenError";
  JWTError["invalidSignature"] = "InvalidSignatureError";
  JWTError["expired"] = "ExpiredSignatureError";
})(JWTError || (JWTError = {}));

export function isJwtError(error) {
  var jwtError;

  try {
    var _error$extensions;

    jwtError = !!findValueInEnum((_error$extensions = error.extensions) === null || _error$extensions === void 0 ? void 0 : _error$extensions.exception.code, JWTError);
  } catch (e) {
    jwtError = false;
  }

  return jwtError;
}
export function getAuthToken() {
  try {
    return localStorage.getItem("token");
  } catch (_unused) {
    return null;
  }
}
export function setAuthToken(token) {
  localStorage.setItem("token", token);
  var authEvent = new Event("auth");
  dispatchEvent(authEvent);
}
// possibly remove callback here and use event emitter
export var invalidTokenLinkWithTokenHandler = function invalidTokenLinkWithTokenHandler(tokenExpirationCallback) {
  var link = onError(function (error) {
    var _error$graphQLErrors;

    var isTokenExpired = (_error$graphQLErrors = error.graphQLErrors) === null || _error$graphQLErrors === void 0 ? void 0 : _error$graphQLErrors.some(isJwtError);

    if (isTokenExpired || error.networkError && error.networkError.statusCode === 401) {
      tokenExpirationCallback();
    }
  });
  return link;
};
export var authLink = setContext(function (_, context) {
  var authToken = getAuthToken();

  if (authToken) {
    return _objectSpread(_objectSpread({}, context), {}, {
      headers: _objectSpread(_objectSpread({}, context.headers), {}, {
        Authorization: authToken ? "JWT ".concat(authToken) : null
      })
    });
  }

  return context;
});