import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import { media, styled } from "@styles";
export var Label = styled.label(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100%;\n  min-height: 190px;\n  display: block;\n  background-color: ", ";\n  padding: 30px;\n  padding: ", ";\n  ", "\n  font-size: ", ";\n  cursor: pointer;\n\n  ", "\n"])), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked ? "28px" : "30px";
}, function (props) {
  return props.checked && "border: 2px solid #00AB4E;";
}, function (props) {
  return props.theme.typography.smallFontSize;
}, media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 30px 20px;\n  "]))));
export var Input = styled.input(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: none;\n"])));
export var HeaderContent = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: ", ";\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: space-between;\n  align-items: center;\n"])), function (props) {
  return props.theme.colors.lightFont;
});
export var FooterContent = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  > div {\n    display: inline-block;\n    padding: 0;\n    margin: 0;\n    margin-right: 0.6rem;\n  }\n"])));
export var MenuItem = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  border-radius: 8px;\n  padding: 0.25rem;\n  :hover {\n    background-color: ", ";\n  }\n"])), function (props) {
  return props.theme.colors.primaryLight;
});