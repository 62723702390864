import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import { styled } from "@styles";
export var Footer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  text-align: right;\n  padding: ", ";\n  ", "\n  button {\n    &:last-child {\n      margin-left: 2rem;\n      margin-right: 0.7rem;\n    }\n  }\n"])), function (props) {
  return "1.1rem ".concat(props.theme.spacing.gutter);
}, function (_ref) {
  var divider = _ref.divider,
      theme = _ref.theme;
  return divider && "border-top: 1px solid ".concat(theme.colors.light, ";");
});