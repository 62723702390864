import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  hr {\n    border: 1px solid #EDEDED;\n    margin: 30px 0 25px;\n\n    ", "\n  }\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      display: none;\n    "]))));
export var Title = styled.h5(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: Nunito Sans;\n  font-style: normal;\n  font-weight: 800;\n  font-size: 24px;\n  color: #0A2540;\n  margin-bottom: 20px;\n"])));
export var WrapperList = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-flow: row wrap;\n  align-items: center;\n  margin-bottom: 20px;\n\n  ", "\n"])), media.smallScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    margin-bottom: 20px;\n  "]))));
export var SubtotalText = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  grid-area: subtotalText;\n  width: 50%;\n"])));
export var SubtotalPrice = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  grid-area: subtotalPrice;\n  text-align: right;\n  width: 50%;\n  ", "\n"])), media.mediumScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));
export var ShippingText = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  grid-area: shippingText;\n  width: 50%;\n"])));
export var ShippingPrice = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  grid-area: shippingPrice;\n  text-align: right;\n  width: 50%;\n  ", "\n"])), media.mediumScreen(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));
export var DiscountText = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  grid-area: discountText;\n  width: 50%;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 22px;\n  color: #00AB4E;\n"])));
export var DiscountPrice = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  grid-area: discountPrice;\n  text-align: right;\n  width: 50%;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 22px;\n  color: #00AB4E;\n\n  ", "\n"])), media.mediumScreen(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));
export var TotalText = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  grid-area: totalText;\n  font-family: Open Sans;\n  font-style: normal;\n  font-size: 16px;\n  line-height: 22px;\n  color: #0A2540;\n  font-weight: bold;\n  width: 50%;\n"])));
export var TotalPrice = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 20px;\n  line-height: 27px;\n  text-align: right;\n  color: #0A2540;\n  width: 50%;\n  ", "\n"])), media.mediumScreen(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));