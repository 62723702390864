import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import gql from "graphql-tag";
import { checkoutPriceFragment } from "./checkout";
export var baseProductFragment = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  fragment BaseProduct on Product {\n    id\n    name\n    slug\n    seoDescription\n    seoTitle\n    thumbnail {\n      url\n      alt\n    }\n    thumbnail2x: thumbnail(size: 510) {\n      url\n    }\n  }\n"])));
export var selectedAttributeFragment = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  fragment SelectedAttributeFields on SelectedAttribute {\n    attribute {\n      id\n      name\n      slug\n    }\n    values {\n      id\n      name\n    }\n  }\n"])));
export var productVariantFragment = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductVariantFields on ProductVariant {\n    id\n    sku\n    name\n    quantityAvailable(countryCode: $countryCode)\n    isAvailable\n    images {\n      id\n      url\n      alt\n    }\n    pricing {\n      onSale\n      priceUndiscounted {\n        ...Price\n      }\n      price {\n        ...Price\n      }\n    }\n    attributes {\n      attribute {\n        id\n        name\n        slug\n      }\n      values {\n        id\n        name\n        value: name\n      }\n    }\n  }\n"])), checkoutPriceFragment);
export var productPricingFragment = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductPricingField on Product {\n    pricing {\n      onSale\n      priceRangeUndiscounted {\n        start {\n          ...Price\n        }\n        stop {\n          ...Price\n        }\n      }\n      priceRange {\n        start {\n          ...Price\n        }\n        stop {\n          ...Price\n        }\n      }\n    }\n  }\n"])), checkoutPriceFragment);
export var productFragment = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  fragment ProductDetails on Product {\n    ...BaseProduct\n    ...ProductPricingField\n    descriptionJson\n    category {\n      id\n      name\n      products(first: 3) {\n        edges {\n          node {\n            ...BaseProduct\n            ...ProductPricingField\n            category {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n    images {\n      id\n      url\n    }\n    attributes {\n      ...SelectedAttributeFields\n    }\n    variants {\n      ...ProductVariantFields\n    }\n    isAvailable\n  }\n"])), baseProductFragment, selectedAttributeFragment, productVariantFragment, productPricingFragment);