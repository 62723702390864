import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { basicProductFragment, productPricingFragment } from "../Product/queries";
export var collectionProductsDataQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query Collection($id: ID!) {\n    collection(id: $id) {\n      id\n      slug\n      name\n      seoDescription\n      seoTitle\n      metadata {\n        key\n        value\n      }\n      backgroundImage {\n        url\n      }\n    }\n    attributes(\n      filter: { inCollection: $id, filterableInStorefront: true }\n      first: 100\n    ) {\n      edges {\n        node {\n          id\n          name\n          slug\n          values {\n            id\n            name\n            slug\n          }\n        }\n      }\n    }\n\n    banners(first: 3) {\n      edges {\n        node {\n          id\n          name\n          slug\n          bannerType\n          images {\n            sortOrder\n            id\n            url\n            redirectUrl\n          }\n        }\n      }\n    }\n  }\n"])));
export var TypedCollectionProductsDataQuery = TypedQuery(collectionProductsDataQuery);
export var collectionProductsQuery = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query CollectionProducts(\n    $id: ID!\n    $attributes: [AttributeInput]\n    $after: String\n    $pageSize: Int\n    $sortBy: ProductOrder\n    $priceLte: Float\n    $priceGte: Float\n    $category: [ID]\n    $search: String\n  ) {\n    collection(id: $id) {\n      id\n      products(\n        after: $after\n        first: $pageSize\n        sortBy: $sortBy\n        filter: {\n          attributes: $attributes\n          price: { gte: $priceGte, lte: $priceLte }\n          categories: $category\n          search: $search\n        }\n      ) {\n        totalCount\n        edges {\n          node {\n            ...BasicProductFields\n            ...ProductPricingField\n            category {\n              id\n              name\n            }\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n\n    banners(first: 3) {\n      edges {\n        node {\n          id\n          name\n          slug\n          images {\n            sortOrder\n            id\n            url\n          }\n        }\n      }\n    }\n  }\n"])), basicProductFragment, productPricingFragment);
export var TypedCollectionProductsQuery = TypedQuery(collectionProductsQuery);