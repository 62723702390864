import { CategoryDetails } from "../../api/categories/CategoryDetails";
import { CategoryAncestorsList } from "../../api/categories/CategoryAncestorsList";
import { CategoryChildrenList } from "../../api/categories/CategoryChildrenList";
import { CategoryList } from "../../api/categories/CategoryList";
import { makeDetails, makeList } from "./utils";
export var useCategoryList = makeList(function (client) {
  return new CategoryList(client);
});
export var useCategoryAncestorsList = makeList(function (client) {
  return new CategoryAncestorsList(client);
});
export var useCategoryChildrenList = makeList(function (client) {
  return new CategoryChildrenList(client);
});
export var useCategoryDetails = makeDetails(function (client) {
  return new CategoryDetails(client);
});