import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import gql from "graphql-tag";
import { orderDetailFragment } from "../fragments/order";
import { invoiceFragment } from "../fragments/invoice";
export var ordersByUser = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query OrdersByUser($perPage: Int!, $after: String) {\n    me {\n      id\n      orders(first: $perPage, after: $after) {\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        edges {\n          node {\n            id\n            token\n            number\n            statusDisplay\n            created\n            status\n            paymentStatus\n            isDelivered\n            metadata {\n              key\n              value\n            }\n            total {\n              gross {\n                amount\n                currency\n              }\n              net {\n                amount\n                currency\n              }\n            }\n            lines {\n              id\n              variant {\n                id\n                product {\n                  name\n                  id\n                }\n              }\n              thumbnail {\n                alt\n                url\n              }\n              thumbnail2x: thumbnail(size: 510) {\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"])));
export var orderDetailsByTokenQuery = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  query OrderByToken($token: UUID!) {\n    orderByToken(token: $token) {\n      ...OrderDetail\n    }\n  }\n"])), orderDetailFragment);
export var userOrderDetailsByTokenQuery = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query UserOrderByToken($token: UUID!) {\n    orderByToken(token: $token) {\n      ...OrderDetail\n      invoices {\n        ...InvoiceFragment\n      }\n    }\n  }\n"])), orderDetailFragment, invoiceFragment);
export var sendinblueTrack = "\n  mutation SendinblueTrack($event: String!, $data: String!) {\n    sendinblueTrack(event: $event, data: $data){\n      success\n    }\n  }\n";