import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import gql from "graphql-tag";
import { accountErrorFragment } from "../fragments/errors";
import { userFragment } from "../fragments/auth";
export var setCustomerDefaultAddress = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation SetCustomerDefaultAddress($id: ID!, $type: AddressTypeEnum!) {\n    accountSetDefaultAddress(id: $id, type: $type) {\n      errors: accountErrors {\n        ...AccountError\n      }\n      user {\n        ...User\n      }\n    }\n  }\n"])), userFragment, accountErrorFragment);
export var deleteUserAddress = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation DeleteUserAddress($addressId: ID!) {\n    accountAddressDelete(id: $addressId) {\n      errors: accountErrors {\n        ...AccountError\n      }\n      user {\n        ...User\n      }\n    }\n  }\n"])), userFragment, accountErrorFragment);
export var createUserAddress = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation CreateUserAddress($input: AddressInput!) {\n    accountAddressCreate(input: $input) {\n      errors: accountErrors {\n        ...AccountError\n      }\n      user {\n        ...User\n      }\n    }\n  }\n"])), userFragment, accountErrorFragment);
export var updateUserAddress = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation UpdateUserAddress($input: AddressInput!, $id: ID!) {\n    accountAddressUpdate(input: $input, id: $id) {\n      errors: accountErrors {\n        ...AccountError\n      }\n      user {\n        ...User\n      }\n    }\n  }\n"])), userFragment, accountErrorFragment);