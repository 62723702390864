/* eslint-disable global-require */
import { generatePageUrl } from "./utils";
import { apiUrl as API_URI } from "../constants";
export var SERVER_URI = API_URI.replace("/graphql/", "");
export var GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY || "";
export var XENDIT_KEY = process.env.XENDIT_KEY;
export var BASE_URL = "/";
export var PRODUCTS_PER_PAGE = 8;
export var SUPPORT_EMAIL = "support@example.com";
export var PROVIDERS = {
  BRAINTREE: {
    label: "Braintree"
  },
  DUMMY: {
    label: "Dummy",
    id: "mirumee.payments.dummy"
  },
  DUMMY_CREDIT_CARD: {
    label: "Dummy Credit Card"
  },
  VIRTUAL_ACCOUNT: {
    label: "Virtual Account Xendit",
    id: "frostock.payment.virtual_account"
  },
  CREDIT_CARD: {
    label: "Credit Card Xendit",
    id: "frostock.payment.credit_card_xendit"
  },
  QRIS: {
    label: "QRCode Xendit",
    id: "frostock.payment.qrcode_xendit"
  },
  STRIPE: {
    label: "Stripe"
  },
  ADYEN: {
    label: "Adyen",
    script: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.js",
      integrity: "sha384-wG2z9zSQo61EIvyXmiFCo+zB3y0ZB4hsrXVcANmpP8HLthjoQJQPBh7tZKJSV8jA",
      crossOrigin: "anonymous"
    },
    style: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.css",
      integrity: "sha384-8ofgICZZ/k5cC5N7xegqFZOA73H9RQ7H13439JfAZW8Gj3qjuKL2isaTD3GMIhDE",
      crossOrigin: "anonymous"
    }
  }
};
export var STATIC_PAGES = [{
  label: "About",
  url: generatePageUrl("about")
}];
export var SOCIAL_MEDIA = [{
  ariaLabel: "facebook",
  href: "https://www.facebook.com/mirumeelabs/",
  path: require("../images/facebook-icon.svg")
}, {
  ariaLabel: "instagram",
  href: "https://www.instagram.com/mirumeelabs/",
  path: require("../images/instagram-icon.svg")
}, {
  ariaLabel: "twitter",
  href: "https://twitter.com/getsaleor",
  path: require("../images/twitter-icon.svg")
}, {
  ariaLabel: "youtube",
  href: "https://www.youtube.com/channel/UCg_ptb-U75e7BprLCGS4s1g/videos",
  path: require("../images/youtube-icon.svg")
}];
export var META_DEFAULTS = {
  custom: [],
  description: "FamilyMart",
  image: "".concat(window.location.origin).concat(require("../images/family_mart_logo.svg")),
  title: "FamilyMart",
  type: "website",
  url: window.location.origin
};
export var CheckoutStep;

(function (CheckoutStep) {
  CheckoutStep[CheckoutStep["Address"] = 1] = "Address";
  CheckoutStep[CheckoutStep["Shipping"] = 2] = "Shipping";
  CheckoutStep[CheckoutStep["Payment"] = 3] = "Payment";
  CheckoutStep[CheckoutStep["Review"] = 4] = "Review";
  CheckoutStep[CheckoutStep["PaymentConfirm"] = 5] = "PaymentConfirm";
})(CheckoutStep || (CheckoutStep = {}));

export var CHECKOUT_STEPS = [{
  index: 0,
  link: "/checkout/address",
  name: "Alamat",
  nextActionName: "Lanjutkan Pengiriman",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Address
}, {
  index: 1,
  link: "/checkout/shipping",
  name: "Pengiriman",
  nextActionName: "Lanjutkan Pengiriman",
  onlyIfShippingRequired: true,
  step: CheckoutStep.Shipping
}, {
  index: 2,
  link: "/checkout/payment",
  name: "Pembayaran",
  nextActionName: "Lanjutkan Pembayaran",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Payment
}, {
  index: 3,
  link: "/checkout/review",
  name: "Review",
  nextActionName: "Place order",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Review
}, {
  index: 4,
  link: "/checkout/payment-confirm",
  name: "Payment confirm",
  onlyIfShippingRequired: false,
  step: CheckoutStep.PaymentConfirm,
  withoutOwnView: true
}];