export var StateItems;

(function (StateItems) {
  StateItems[StateItems["LOADED"] = 0] = "LOADED";
  StateItems[StateItems["USER"] = 1] = "USER";
  StateItems[StateItems["SIGN_IN_TOKEN"] = 2] = "SIGN_IN_TOKEN";
  StateItems[StateItems["SIGN_IN_TOKEN_REFRESHING"] = 3] = "SIGN_IN_TOKEN_REFRESHING";
  StateItems[StateItems["SIGN_IN_TOKEN_VERIFYING"] = 4] = "SIGN_IN_TOKEN_VERIFYING";
  StateItems[StateItems["CHECKOUT"] = 5] = "CHECKOUT";
  StateItems[StateItems["SUMMARY_PRICES"] = 6] = "SUMMARY_PRICES";
  StateItems[StateItems["PROMO_CODE"] = 7] = "PROMO_CODE";
  StateItems[StateItems["PAYMENT"] = 8] = "PAYMENT";
})(StateItems || (StateItems = {}));