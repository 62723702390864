import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React from "react";
import { useSaleorClient } from "./helpers";

// keep track of called mutation
var useMutationTracking = function () {
  var mostRecentMutation = 0;

  var generateNewMutationId = function generateNewMutationId() {
    mostRecentMutation += 1;
    return mostRecentMutation;
  };

  var isMostRecentMutation = function isMostRecentMutation(mutationId) {
    return mostRecentMutation === mutationId;
  };

  return function () {
    return {
      generateNewMutationId: generateNewMutationId,
      isMostRecentMutation: isMostRecentMutation
    };
  };
}();

var initialState = {
  called: false,
  data: null,
  error: null,
  loading: false
};

var useMutation = function useMutation(mutation) {
  var baseVariables = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var baseOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var saleor = useSaleorClient();

  var _useMutationTracking = useMutationTracking(),
      generateNewMutationId = _useMutationTracking.generateNewMutationId,
      isMostRecentMutation = _useMutationTracking.isMostRecentMutation;

  var _React$useState = React.useState(initialState),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      result = _React$useState2[0],
      setResult = _React$useState2[1];

  var handleMutationStart = function handleMutationStart() {
    if (!result.loading) {
      setResult({
        called: true,
        data: null,
        error: null,
        loading: true
      });
    }
  };

  var handleMutationError = function handleMutationError(error, mutationId) {
    if (isMostRecentMutation(mutationId)) {
      setResult(function (prevState) {
        return _objectSpread(_objectSpread({}, prevState), {}, {
          error: error,
          loading: false
        });
      });
    }
  };

  var handleMutationComplete = function handleMutationComplete(data, mutationId) {
    if (isMostRecentMutation(mutationId)) {
      setResult(function (prevState) {
        return _objectSpread(_objectSpread({}, prevState), {}, {
          data: data,
          loading: false
        });
      });
    }
  };

  var runMutation = React.useCallback(function (variables, options) {
    return new Promise(function (resolve) {
      handleMutationStart();
      var mutationId = generateNewMutationId();

      var apolloVariables = _objectSpread(_objectSpread({}, baseVariables), variables);

      var apolloOptions = _objectSpread(_objectSpread({}, baseOptions), options);

      saleor.legacyAPIProxy[mutation](apolloVariables, apolloOptions).then(function (data) {
        handleMutationComplete(data.data, mutationId);
        resolve(data);
      }).catch(function (err) {
        handleMutationError(err, mutationId);
        resolve(null);
      });
    });
  }, [mutation, baseOptions]);
  return [runMutation, result];
};

export var mutationFactory = function mutationFactory(mutation) {
  return function (variables, options) {
    return useMutation(mutation, variables, options);
  };
};