import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 60px 0;\n\n  ", "\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin: 40px 0;\n  "]))));
export var Header = styled.h3(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: Nunito Sans;\n  font-style: normal;\n  font-weight: 800;\n  font-size: 20px;\n  line-height: 27px;\n  border-bottom: 1px solid #EEEEEE;\n  padding-bottom: 17px;\n"])));
export var SubHeader = styled.h5(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 18px;\n  line-height: 149.02%;\n  /* identical to box height, or 27px */\n  text-align: center;\n  color: #787878;\n  margin: 30px auto 27px;\n"])));
export var Box = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  margin: 0 auto;\n  width: 70%;\n  border: 1px solid #D4D4D4;\n  text-align: center;\n  background: #fff;\n  padding: 25px;\n\n  \n  ", "\n"])), media.smallScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    width: 100%;\n  "]))));
export var ThankYouHeader = styled.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  font-size: 24px;\n  font-weight: 800;\n  font-family: \"Nunito Sans\", sans-serif;\n  margin: 0;\n  line-height: 110%;\n  text-align: center;\n\n  span {\n    font-weight: ", ";\n  }\n  padding-bottom: 40px;\n\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Paragraph = styled.p(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  line-height: 170%;\n\n  span {\n    font-weight: ", ";\n  }\n"])), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Buttons = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  width: 50%;\n  margin: 40px auto;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-column-gap: 30px;\n  button {\n    padding-left: 0;\n    padding-right: 0;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-row-gap: 20px;\n    width: 100%;\n    margin-top: 20px;\n  "]))));