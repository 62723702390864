import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { CheckoutStep } from "@temp/core/config";
import { checkIfShippingRequiredForProducts } from "@utils/core";
import { isPriceEqual } from "@utils/money";
export var useCheckoutStepState = function useCheckoutStepState(items, checkout, payment, totalPrice) {
  var isShippingRequiredForProducts = checkIfShippingRequiredForProducts(items);
  var history = useHistory();
  var isCheckoutPriceEqualPaymentPrice = (payment === null || payment === void 0 ? void 0 : payment.total) && (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross) && isPriceEqual(payment.total, totalPrice.gross);

  var getMaxPossibleStep = function getMaxPossibleStep() {
    if (!(checkout !== null && checkout !== void 0 && checkout.id) && items) {
      // we are creating checkout during address set up
      return CheckoutStep.Address;
    }

    var isShippingAddressSet = !isShippingRequiredForProducts || !!(checkout !== null && checkout !== void 0 && checkout.shippingAddress);
    var isBillingAddressSet = !!(checkout !== null && checkout !== void 0 && checkout.billingAddress);
    var isShippingMethodSet = !isShippingRequiredForProducts || !!(checkout !== null && checkout !== void 0 && checkout.shippingMethod);
    var isPaymentMethodSet = !!(payment !== null && payment !== void 0 && payment.id) && isCheckoutPriceEqualPaymentPrice;

    if (!isShippingAddressSet || !isBillingAddressSet) {
      return CheckoutStep.Address;
    }

    if (!isShippingMethodSet) {
      return CheckoutStep.Shipping;
    }

    if (!isPaymentMethodSet) {
      return CheckoutStep.Payment;
    }

    return CheckoutStep.Review;
  };

  var getRecommendedStep = function getRecommendedStep(_newMaxPossibleStep) {
    return CheckoutStep.Address; //allways redirect address

    /* function before select recomendation step*/
    // const isPaymentRecreateRequired =
    //   newMaxPossibleStep > CheckoutStep.Shipping &&
    //   !isCheckoutPriceEqualPaymentPrice;
    // if (isPaymentRecreateRequired && isShippingRequiredForProducts) {
    //   return CheckoutStep.Shipping;
    // }
    // if (isPaymentRecreateRequired) {
    //   return CheckoutStep.Payment;
    // }
    // return newMaxPossibleStep;
  };

  var _useState = useState(getMaxPossibleStep()),
      _useState2 = _slicedToArray(_useState, 2),
      maxPossibleStep = _useState2[0],
      setMaxPossibleStep = _useState2[1];

  var _useState3 = useState(getRecommendedStep(maxPossibleStep)),
      _useState4 = _slicedToArray(_useState3, 2),
      recommendedStep = _useState4[0],
      setRecommendedStep = _useState4[1];

  useEffect(function () {
    var newMaxPossibleStep = getMaxPossibleStep();
    var newRecommendedStep = getRecommendedStep(newMaxPossibleStep);

    if (maxPossibleStep !== newMaxPossibleStep) {
      setMaxPossibleStep(newMaxPossibleStep);
    }

    if (recommendedStep !== newRecommendedStep) {
      // setRecommendedStep(newRecommendedStep);
      setRecommendedStep(CheckoutStep.Address);
    }
  }, [checkout, items, payment, totalPrice]);
  useEffect(function () {
    history.push('/checkout/address');
  }, []);
  return {
    recommendedStep: recommendedStep,
    maxPossibleStep: maxPossibleStep
  };
};