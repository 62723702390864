import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0;\n  margin-top: ", ";\n  margin-left: ", ";\n"])), function (props) {
  return "-".concat(props.theme.spacing.gutter);
}, function (props) {
  return "-".concat(props.theme.spacing.gutter);
});
export var Tile = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: 0;\n  padding-top: ", ";\n  padding-left: ", ";\n  width: calc(100% / ", ");\n\n  ", "\n"])), function (props) {
  return props.theme.spacing.gutter;
}, function (props) {
  return props.theme.spacing.gutter;
}, function (props) {
  return props.columns;
}, media.smallScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    width: 100%;\n  "]))));