import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  // background-color: ", ";\n  background-color: #fff;\n  border: 1px solid #D4D4D4;\n\n  ", "\n  ", "\n"])), function (props) {
  return props.theme.colors.light;
}, media.mediumScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: calc(100% - 86px);\n    left: 0%;\n    transition: all 0.5s ease;\n    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);\n    background: #fff;\n    z-index:3;\n  "]))), function (props) {
  return props.mobileCartOpened && media.mediumScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    top: 0%;\n    overflow-y: scroll;\n  "])));
});
export var Content = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  padding: 0 20px 30px 20px;\n"])));
export var ProductLine = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 30px 0;\n"])));
export var CartSummaryProductList = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-bottom: 30px;\n"])));
export var Warning = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    color: red;\n    font-size: 12px;\n    margin-top:10px;\n"])));
export var HR = styled.hr(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid ", ";\n  margin: 0;\n  padding: 0;\n"])), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  padding: 30px 20px;\n  display: flex;\n  justify-content: space-between;\n  margin: 0;\n  font-size: 24px;\n  font-weight: 800;\n  ", "\n"])), media.mediumScreen(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    font-size: ", ";\n    cursor: pointer;\n  "])), function (props) {
  return props.theme.typography.h4FontSize;
}));
export var ArrowUp = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: none;\n  ", "\n  ", "\n"])), media.mediumScreen(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    display: unset;\n  "]))), function (props) {
  return props.mobileCartOpened && media.mediumScreen(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    transform: rotate(180deg);\n  "])));
});
export var CostLine = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  font-size: ", ";\n  span {\n    display: inline-block;\n  }\n  font-weight: ", ";\n"])), function (props) {
  return props.last ? "20px" : "16px";
}, function (props) {
  return props.last ? props.theme.typography.boldFontWeight : "normal";
});
export var Costs = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n\n  div {\n    margin-bottom: 20px;\n    &:last-of-type {\n      margin-bottom: 0px;\n    }\n  }\n"])));