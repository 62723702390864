import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: auto;\n  display: flex;\n  flex-direction: column;\n  font-size: ", ";\n"])), function (props) {
  return props.theme.typography.smallFontSize;
});
export var SortLine = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding-bottom: 0.5rem;\n"])));
export var Value = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 19px;\n  color: #0A2540;\n\n  ", "\n"])), media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 17px;\n    color: #000000;\n    margin-left: 10px;\n  "]))));
export var Indicator = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  right: 1rem;\n  transition-duration: 0.3s;\n  transform: ", ";\n"])), function (props) {
  return props.rotate === "true" ? "rotate(180deg)" : "rotate(0deg)";
});
export var Label = styled.span(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 19px;\n  color: #000000;\n\n  ", "\n"])), media.smallScreen(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 17px;\n    color: #000000;\n  "]))));