import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;

import { media, styled } from '@styles';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: #FFFFFF;\n  border: 1px solid #D4D4D4;\n  box-sizing: border-box;\n  border-radius: 4px;\n  padding: 15px;\n  margin: 20px 0;\n  display: flex;\n  align-items: center;\n  position: relative;\n  cursor: pointer;\n\n\n  &:after {\n    content: url(\"../../../images/arrow-right.png\");\n    position: absolute;\n    right: 10%;\n    transition:  all 0.3s ease-in;\n\n    @media (max-width: $small-screen) {\n      right: 0;\n    }\n  }\n"])));
export var Icon = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex: 0 0 20%;\n  width: 20%;\n  padding: 0 5px;\n  align-items: center;  \n  display: flex;\n"])));
export var Title = styled.h5(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 34px;\n  color: #000000;\n  margin-bottom: 20px;\n"])));
export var TitleDetail = styled(Title)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-weight: 800;\n  font-family: Nunito Sans;\n\n  button {\n    float: right;\n    margin-top: 5px;\n  }\n"])));
export var Text = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  flex: 0 0 70%;\n  width: 70%;\n  padding: 0 5px;\n\n  p {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 18px;\n    line-height: 25px;\n    color: #0A2540;\n  }\n  \n  span {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 19px;\n    color: #838383;\n  }\n"])));
export var WrapperList = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n\n"])));
export var ItemPromo = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  background: #F1FFF7;\n  border: 1px solid #00AB4E;\n  padding: 20px;\n  margin-bottom: 20px;\n\n  & > div {\n    display: flex;\n    flex-flow: row wrap;\n    \n    button {\n      width: 20%;\n      margin-left: 5%;\n    }\n  }\n\n  h5 {\n    font-family: Nunito Sans;\n    font-style: normal;\n    font-weight: 800;\n    font-size: 20px;\n    line-height: 27px;\n    color: #000000;\n    width: 75%;\n\n    ", "\n  }\n\n  .text-detail {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 22px;\n    color: #8E8E8E;\n\n    span {\n      color: red;\n      font-size: 12px;\n    }\n\n    ", "\n  }\n\n  button {\n    float: right;\n    background: #00AB4E;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 16px;\n    line-height: 22px;\n    text-align: center;\n    color: #FFFFFF;\n    padding: 10px;\n  }\n\n  .danger {\n    background: red;\n  }\n"])), media.smallScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n      font-size: 16px;\n    "]))), media.smallScreen(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n      font-size: 14px;\n    "]))));
export var WrapperInput = styled.form(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-flow: row wrap;\n  position: relative;\n  margin-bottom: 30px;\n\n  input {\n    background: #FFFFFF;\n    border: 1px solid #CBD5E0;\n    padding: 13px 20px;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 18px;\n    line-height: 25px;    \n    color: #000000;\n    width: 60%;\n\n    &:focus {\n      outline: none;\n    }\n  }\n\n  button {\n    background: #00AB4E;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 16px;\n    line-height: 22px;\n    display: block;\n    align-items: center;\n    text-align: center;\n    color: #FFFFFF;\n    width: 35%;\n    margin-left: 5%;\n  }\n"])));
export var TitleList = styled.h5(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  font-family: Nunito Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 18px;\n  line-height: 25px;\n  color: #000000;\n  margin-bottom: 20px;\n"])));