import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Row = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  height: 54px;\n  cursor: pointer;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 15px;\n  line-height: 18px;\n  color: #0A2540;\n"])));
export var HeaderRow = styled(Row)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  cursor: default;\n  background: #F5F5F5;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 22px;\n"])));
export var Button = styled.button(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background: #00AB4E;\n  boder:none;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 15px;\n  line-height: 20px;\n  display: flex;\n  align-items: center;\n  text-align: center;\n  color: #FFFFFF;\n  padding: 6px 22px;\n\n  &:focus {\n    outline: none;\n  }\n"])));
export var IndexNumber = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 15%;\n  padding: 20px;\n  text-align: left;\n  ", "\n"])), media.smallScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n     width: 50%;\n  "]))));
export var ProductsOrdered = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 25%;\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: center;\n\n  img {\n    max-width: 50px;\n    height: auto;\n  }\n"])));
export var DateOfOrder = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  width: 25%;\n"])));
export var Value = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  width: 10%;\n"])));
export var Status = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  width: 25%;\n  ", "\n"])), media.smallScreen(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n     width: 50%;\n  "]))));