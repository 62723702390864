import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { media, styled } from "@styles";
export var PaymentForm = styled.form(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 60%;\n"])));
export var PaymentInput = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n\n  & > label {\n    margin-bottom: 15px;\n    color: #1A202C;\n    font-size: 15px;\n    display: block;\n\n    ", "\n  }\n\n  & > span {\n    margin-bottom: 10px;\n    display: block;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      margin-bottom: 5px;\n    "]))), media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    padding-right:  0;\n    margin-bottom: 10px;\n  "]))));
PaymentInput.displayName = "S.PaymentInput";
export var Grid = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  ", "\n  & > div {\n    padding-right: ", ";\n    &:last-child {\n      padding-right: 0;\n    }\n    ", "\n  }\n"])), media.smallScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n     flex-direction: column;\n  "]))), function (props) {
  return props.theme.spacing.spacer;
}, media.smallScreen(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n      padding-right:  0;\n      \n    "]))));