import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { styled } from "@styles";
export var BoldTitle = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-weight: ", ";\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var TextContent = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-top: 0.5rem;\n  margin-bottom: ", ";\n"])), function (props) {
  return props.theme.spacing.spacer;
});
export var FooterContent = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  > div {\n    display: inline-block;\n    padding: 0;\n    margin: 0;\n    margin-right: 0.6rem;\n  }\n"])));