import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  box-shadow: 6px 0px 30px rgba(0, 0, 0, 0.15);\n"])));
export var Content = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  height: ", ";\n  overflow: auto;\n"])), function (_ref) {
  var spacing = _ref.theme.spacing;
  return "calc(100vh - ".concat(spacing.gutter, ")");
});
export var Footer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: ", ";\n"])), function (props) {
  return props.theme.colors.light;
}, function (_ref2) {
  var spacing = _ref2.theme.spacing;
  return "1.3rem ".concat(spacing.gutter, " 1rem ").concat(spacing.gutter);
});
export var Option = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  cursor: ", ";\n"])), function (props) {
  return props.disabled ? "default" : "pointer";
});