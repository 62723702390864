import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { styled } from "@styles";
import { activeLabelStyles } from "../InputLabel";

var getEdgeColor = function getEdgeColor(_ref) {
  var active = _ref.active,
      error = _ref.error,
      disabled = _ref.disabled,
      theme = _ref.theme;
  var hovered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (disabled) {
    return theme.colors.disabled;
  }

  if (error) {
    return theme.colors.error;
  }

  if (hovered) {
    return theme.colors.secondary;
  }

  return active ? theme.colors.secondary : theme.colors.dark;
};

export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  color: ", ";\n  transition: all 0.3s ease;\n  \n  label {\n    margin-bottom: 15px;\n    color: #1A202C;\n    font-size: 15px;\n    display: block;\n  }\n"])), function (props) {
  return getEdgeColor(props);
});
export var Content = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
export var InputWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n"])));
export var Input = styled.input(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  padding: 0.8rem 1rem;\n  border: 1px solid #00AB4E;\n  margin: 0;\n  width: 100%;\n  font-size: ", ";\n  outline: none;\n  background-color: transparent;\n  &:-webkit-autofill {\n    & + label {\n      ", ";\n    }\n  }\n"])), function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return activeLabelStyles(props.theme, props.labelBackground);
});