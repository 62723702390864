import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { styled } from "@styles";
export var ErrorMessage = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n"])), function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.input.labelFontSize;
});
export var ErrorParagraph = styled.p(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin: 0;\n"])));
ErrorMessage.displayName = "S.ErrorMessage";