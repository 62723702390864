import { useSaleorClient } from "./helpers";

var useHook = function useHook(dataName) {
  var saleor = useSaleorClient();

  var getHookData = function getHookData() {
    return saleor[dataName];
  };

  return getHookData();
};

export var hookFactory = function hookFactory(query) {
  return function () {
    return useHook(query);
  };
};