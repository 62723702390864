import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n  margin-top: 20px;\n"])));
export var Tile = styled.label(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n  // background-color: ", ";\n  // ", "\n  cursor: pointer;\n\n  .image-ic {\n    margin-top: 10px;\n\n    img {\n      margin-right: 15px;\n      width: 80px;\n      object-fit: contain;\n    }\n\n    .border-img {\n      border: 1px solid #e6e6e6;\n      padding: 10px;\n    }\n  }\n"])), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #00AB4E;";
});