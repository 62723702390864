import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import gql from "graphql-tag";
import { paymentGatewayFragment } from "./payment";
export var checkoutPriceFragment = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  fragment Price on TaxedMoney {\n    gross {\n      amount\n      currency\n    }\n    net {\n      amount\n      currency\n    }\n  }\n"])));
export var checkoutAddressFragment = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  fragment Address on Address {\n    id\n    firstName\n    lastName\n    companyName\n    streetAddress1\n    streetAddress2\n    areaCode\n    geoLatitude\n    geoLongitude\n    city\n    postalCode\n    country {\n      code\n      country\n    }\n    countryArea\n    phone\n    isDefaultBillingAddress\n    isDefaultShippingAddress\n    areaCode\n    geoLatitude\n    geoLongitude\n  }\n"])));
export var checkoutProductVariantFragment = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductVariant on ProductVariant {\n    id\n    name\n    sku\n    quantityAvailable\n    isAvailable\n    pricing {\n      onSale\n      priceUndiscounted {\n        ...Price\n      }\n      price {\n        ...Price\n      }\n    }\n    attributes {\n      attribute {\n        id\n        name\n      }\n      values {\n        id\n        name\n        value: name\n      }\n    }\n    product {\n      id\n      name\n      thumbnail {\n        url\n        alt\n      }\n      thumbnail2x: thumbnail(size: 510) {\n        url\n      }\n      productType {\n        id\n        isShippingRequired\n      }\n      category {\n        id\n        name\n      }\n    }\n  }\n"])), checkoutPriceFragment);
export var checkoutShippingMethodFragment = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  fragment ShippingMethod on ShippingMethod {\n    id\n    name\n    rateName\n    price {\n      currency\n      amount\n    }\n  }\n"])));
export var checkoutLineFragment = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  fragment CheckoutLine on CheckoutLine {\n    id\n    quantity\n    totalPrice {\n      ...Price\n    }\n    variant {\n      ...ProductVariant\n    }\n    isPreOrder\n    maximumFulfillmentDays\n    appliedAddon {\n      name\n      price\n      quantity\n      valueOption\n      unit\n      lineId\n      AddOnType\n      erpId\n      addonId\n      forstokId\n    }\n  }\n"])), checkoutPriceFragment, checkoutProductVariantFragment);
export var checkoutFragment = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  fragment Checkout on Checkout {\n    token\n    id\n    totalPrice {\n      ...Price\n    }\n    subtotalPrice {\n      ...Price\n    }\n    billingAddress {\n      ...Address\n    }\n    shippingAddress {\n      ...Address\n    }\n    email\n    availableShippingMethods {\n      ...ShippingMethod\n    }\n    shippingMethod {\n      ...ShippingMethod\n    }\n    shippingPrice {\n      ...Price\n    }\n    lines {\n      ...CheckoutLine\n    }\n    isShippingRequired\n    discount {\n      currency\n      amount\n    }\n    promoShipping {\n      id\n      name\n      slug\n      discountValue\n    }\n    promoCreditCard {\n      id\n      name\n      slug\n      discountPercent\n      discountAmount\n      promoCode\n    }\n    appliedShippingPromo {\n      ...Price\n    }\n    defaultVoucherDiscount {\n      ...Price\n    }\n    discountName\n    translatedDiscountName\n    voucherCode\n    availablePaymentGateways {\n      ...PaymentGateway\n    }\n    user {\n      id\n      cardAccount {\n        id\n        maskCardNumber\n        cardNumber\n        isActive\n        expMonth\n        expYear\n      }\n    }\n  }\n"])), checkoutLineFragment, checkoutAddressFragment, checkoutPriceFragment, checkoutShippingMethodFragment, paymentGatewayFragment);