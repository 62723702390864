import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import ReactSVG from "react-svg";
import { styled } from "@styles";
export var Wrapper = styled.section(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  background-color: ", ";\n  padding: 0 1.3rem;\n  font-size: 12;\n"])), function (props) {
  return props.theme.colors.bannerBackground;
});
export var BorderedWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  max-height: ", ";\n  padding: 1.5em 0;\n  background-image: ", ";\n  background-position: bottom;\n  background-size: 12px 1px;\n  background-repeat: repeat-x;\n"])), function (props) {
  return props.theme.demoBanner.height;
}, function (props) {
  return "linear-gradient(\n    to right,\n    ".concat(props.theme.colors.bannerEdge, " 13%,\n    rgba(255, 255, 255, 0) 0%\n  );");
});
export var LogoWrapper = styled(ReactSVG)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  line-height: 0;\n"])));
export var LinkList = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
export var Link = styled.a(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 1rem;\n"])));
export var TextEmphasis = styled.span(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: ", ";\n  text-transform: uppercase;\n  font-weight: 600;\n"])), function (props) {
  return props.theme.colors.bannerLink;
});
export var Divider = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  border-right: 1px solid ", ";\n  height: 2rem;\n"])), function (props) {
  return props.theme.colors.bannerEdge;
});