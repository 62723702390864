import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  // grid-row-gap: 6px;\n  grid-column-gap: 20px;\n  grid-template-columns: 60px auto auto;\n  grid-template-areas:\n    \"photo name price\"\n    \"photo sku .\"\n    \"photo . .\"\n    \"photo quantity .\";\n"])));
export var Photo = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  grid-area: photo;\n  width: min-content;\n\n  img {\n    height: auto;\n    max-width: 60px;\n  }\n"])));
export var Addons = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin: 6px 0;\n  text-align: left;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 15px;\n  color: #787878;\n"])));
export var Sku = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-area: sku;\n  color: ", ";\n  font-size: ", ";\n"])), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var Name = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  grid-area: name;\n  font-weight: 700;\n  font-size: 14px;\n"])));
export var Price = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  grid-area: price;\n  text-align: right;\n  font-size: ", ";\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 14px;\n"])), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Quantity = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  color: ", ";\n  font-size: ", ";\n"])), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});