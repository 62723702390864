import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import { css } from "styled-components";
import { largeScreen, mediumScreen, smallScreen, xLargeScreen, xxLargeScreen, xxxLargeScreen } from "./constants";
var breakpoints = {
  largeScreen: largeScreen,
  mediumScreen: mediumScreen,
  smallScreen: smallScreen,
  xLargeScreen: xLargeScreen,
  xxLargeScreen: xxLargeScreen,
  xxxLargeScreen: xxxLargeScreen
};
export var media = Object.keys(breakpoints).reduce(function (acc, label) {
  acc[label] = function (literals) {
    for (var _len = arguments.length, placeholders = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      placeholders[_key - 1] = arguments[_key];
    }

    return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        @media (max-width: ", "px) {\n          ", "\n        }\n      "])), breakpoints[label], css.apply(void 0, [literals].concat(placeholders)));
  };

  return acc;
}, // tslint:disable-next-line:no-object-literal-type-assertion
{});