import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// errors are nested in data as it currently stands in the API
// this helper extracts all errors present
export var getErrorsFromData = function getErrorsFromData(data) {
  try {
    var error = Object.keys(data).reduce(function (acc, key) {
      return _objectSpread(_objectSpread({}, acc), data[key].errors && !!data[key].errors.length && {
        userInputErrors: data[key].errors
      });
    }, {});
    return Object.keys(error).length ? error : null;
  } catch (e) {
    return null;
  }
};
export var isDataEmpty = function isDataEmpty(data) {
  return Object.keys(data).reduce(function (_, key) {
    return !!data[key];
  }, true);
};
export function getMappedData(mapFn, data) {
  if (!data) {
    return null;
  }

  var mappedData = mapFn(data);
  var result = mappedData && !!Object.keys(mappedData).length ? mappedData : null;
  return result;
}
export var mergeEdges = function mergeEdges(prevEdges, newEdges) {
  return [].concat(_toConsumableArray(prevEdges), _toConsumableArray(newEdges.filter(function (edge) {
    return !prevEdges.some(function (e) {
      return e.node.id === edge.node.id;
    });
  })));
};
export function filterNotEmptyArrayItems(value) {
  return value !== null && value !== undefined;
}
export function findValueInEnum(needle, haystack) {
  var match = Object.entries(haystack).find(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        value = _ref2[1];

    return value === needle;
  });

  if (!match) {
    throw new Error("Value ".concat(needle, " not found in enum"));
  }

  return needle;
}