import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import gql from "graphql-tag";
import { userFragment } from "../fragments/auth";
import { accountErrorFragment } from "../fragments/errors";
export var changeUserPassword = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  mutation PasswordChange($newPassword: String!, $oldPassword: String!) {\n    passwordChange(newPassword: $newPassword, oldPassword: $oldPassword) {\n      errors: accountErrors {\n        ...AccountError\n      }\n    }\n  }\n"])), accountErrorFragment);
export var registerAccount = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation RegisterAccount(\n    $email: String!\n    $password: String!\n    $redirectUrl: String!\n  ) {\n    accountRegister(\n      input: { email: $email, password: $password, redirectUrl: $redirectUrl }\n    ) {\n      accountErrors {\n        field\n        message\n        code\n      }\n      requiresConfirmation\n    }\n  }\n"])));
export var resetPasswordRequest = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation ResetPasswordRequest($email: String!, $redirectUrl: String!) {\n    requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {\n      accountErrors {\n        field\n        message\n        code\n      }\n    }\n  }\n"])));
export var accountUpdate = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation AccountUpdate($input: AccountInput!) {\n    accountUpdate(input: $input) {\n      errors: accountErrors {\n        ...AccountError\n      }\n      user {\n        ...User\n      }\n    }\n  }\n"])), userFragment, accountErrorFragment);
export var setPassword = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation SetPassword($token: String!, $email: String!, $password: String!) {\n    setPassword(token: $token, email: $email, password: $password) {\n      errors: accountErrors {\n        ...AccountError\n      }\n      token\n      user {\n        ...User\n      }\n      accountErrors {\n        field\n        message\n        code\n      }\n    }\n  }\n"])), userFragment, accountErrorFragment);