import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var LocalStorageManager = function LocalStorageManager(handler, saleorState) {
  var _this = this;

  _classCallCheck(this, LocalStorageManager);

  this.arraysAreEqual = function (a, b) {
    var dataA = a.map(function (key) {
      return {
        AddOnType: key.AddOnType,
        name: key.name,
        price: key.price,
        quantity: key.quantity,
        unit: key.unit,
        valueOption: key.valueOption
      };
    });
    var dataB = b.map(function (key) {
      return {
        AddOnType: key.AddOnType,
        name: key.name,
        price: key.price,
        quantity: key.quantity,
        unit: key.unit,
        valueOption: key.valueOption
      };
    });
    return JSON.stringify(dataA) === JSON.stringify(dataB);
  };

  this.arraysAreNotEqual = function (a, b) {
    var dataA = a.map(function (key) {
      return {
        AddOnType: key.AddOnType,
        name: key.name,
        price: key.price,
        quantity: key.quantity,
        unit: key.unit,
        valueOption: key.valueOption
      };
    });
    var dataB = b.map(function (key) {
      return {
        AddOnType: key.AddOnType,
        name: key.name,
        price: key.price,
        quantity: key.quantity,
        unit: key.unit,
        valueOption: key.valueOption
      };
    });
    return JSON.stringify(dataA) !== JSON.stringify(dataB);
  };

  this.getHandler = function () {
    return _this.handler;
  };

  this.addItemToCart = function (variantId, quantity, addonsToParams, id) {
    var _this$saleorState$che;

    var lines = ((_this$saleorState$che = _this.saleorState.checkout) === null || _this$saleorState$che === void 0 ? void 0 : _this$saleorState$che.lines) || [];
    var variantInCheckout = lines.find(function (variant) {
      return variant.variant.id === variantId && _this.arraysAreEqual(variant.appliedAddon, addonsToParams.appliedAddon);
    });
    var alteredLines = lines.filter(function (variant) {
      return variant.variant.id !== variantId || _this.arraysAreNotEqual(variant.appliedAddon, addonsToParams.appliedAddon);
    });
    var newVariantQuantity = variantInCheckout ? variantInCheckout.quantity + quantity : quantity;

    if (variantInCheckout) {
      variantInCheckout.quantity = newVariantQuantity;
      alteredLines.push(variantInCheckout);
    } else {
      variantInCheckout = _objectSpread({
        quantity: quantity,
        variant: {
          id: variantId,
          varId: id
        }
      }, addonsToParams);
      alteredLines.push(variantInCheckout);
    }

    var alteredCheckout = _this.saleorState.checkout ? _objectSpread(_objectSpread({}, _this.saleorState.checkout), {}, {
      lines: alteredLines
    }) : {
      lines: alteredLines
    };

    _this.handler.setCheckout(alteredCheckout);

    return alteredCheckout;
  };

  this.removeItemFromCart = function (variantId, line) {
    var _this$saleorState$che2;

    var lines = ((_this$saleorState$che2 = _this.saleorState.checkout) === null || _this$saleorState$che2 === void 0 ? void 0 : _this$saleorState$che2.lines) || [];
    var variantInCheckout = lines.find(function (variant) {
      return variant.variant.id === variantId && _this.arraysAreEqual(variant.appliedAddon, line.appliedAddon);
    });
    var alteredLines = lines.filter(function (variant) {
      return variant.variant.id !== variantId || _this.arraysAreNotEqual(variant.appliedAddon, line.appliedAddon);
    });

    if (variantInCheckout) {
      variantInCheckout.quantity = 0;
      alteredLines.push(variantInCheckout);
    }

    var alteredCheckout = _this.saleorState.checkout ? _objectSpread(_objectSpread({}, _this.saleorState.checkout), {}, {
      lines: alteredLines
    }) : {
      lines: alteredLines
    };

    _this.handler.setCheckout(alteredCheckout);

    return alteredCheckout;
  };

  this.subtractItemFromCart = function (variantId) {
    var _this$saleorState$che3;

    var lines = ((_this$saleorState$che3 = _this.saleorState.checkout) === null || _this$saleorState$che3 === void 0 ? void 0 : _this$saleorState$che3.lines) || [];
    var variantFromCart = lines.find(function (variant) {
      return variant.variant.id === variantId;
    });
    var alteredLines = lines.filter(function (variant) {
      return variant.variant.id !== variantId;
    });
    var newVariantQuantity = variantFromCart ? variantFromCart.quantity - 1 : 0;

    if (variantFromCart) {
      variantFromCart.quantity = newVariantQuantity;
      alteredLines.push(variantFromCart);
    }

    var alteredCheckout = _this.saleorState.checkout ? _objectSpread(_objectSpread({}, _this.saleorState.checkout), {}, {
      lines: alteredLines
    }) : {
      lines: alteredLines
    };

    _this.handler.setCheckout(alteredCheckout);

    return alteredCheckout;
  };

  this.updateItemInCart = function (variantId, quantity, params) {
    var _this$saleorState$che4;

    var lines = ((_this$saleorState$che4 = _this.saleorState.checkout) === null || _this$saleorState$che4 === void 0 ? void 0 : _this$saleorState$che4.lines) || [];
    var variantInCheckout = lines.find(function (variant) {
      return variant.variant.id === variantId && _this.arraysAreEqual(variant.appliedAddon, params.appliedAddon);
    });
    var alteredLines = lines.filter(function (variant) {
      return variant.variant.id !== variantId || _this.arraysAreNotEqual(variant.appliedAddon, params.appliedAddon);
    });

    if (variantInCheckout) {
      variantInCheckout.quantity = quantity;
      alteredLines.push(variantInCheckout);
    }

    var alteredCheckout = _this.saleorState.checkout ? _objectSpread(_objectSpread({}, _this.saleorState.checkout), {}, {
      lines: alteredLines
    }) : {
      lines: alteredLines
    };

    _this.handler.setCheckout(alteredCheckout);

    return alteredCheckout;
  };

  this.handler = handler;
  this.saleorState = saleorState;
};