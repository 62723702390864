import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { styled } from "@styles";
export var Header = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: ", ";\n  ", "\n"])), function (props) {
  return "1.1rem ".concat(props.theme.spacing.gutter);
}, function (_ref) {
  var divider = _ref.divider,
      theme = _ref.theme;
  return divider && "border-bottom: 1px solid ".concat(theme.colors.light, ";");
});
export var Title = styled.h4(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n  text-transform: uppercase;\n  padding-right: 0.6rem;\n  margin: 0;\n"])), function (_ref2) {
  var size = _ref2.size,
      typography = _ref2.theme.typography;
  return size === "lg" ? typography.h4FontSize : typography.baseFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFont;
});
export var Paragraph = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: ", ";\n  padding-right: 0.6rem;\n  margin: 0;\n"])), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.colors.lightFont;
});