import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Address from "./address";
import * as User from "./user";
// TODO: Add ability to pass custom fragments to mutations
export var MUTATIONS = {
  AccountUpdate: function AccountUpdate(client, options) {
    return client.mutate(_objectSpread({
      mutation: User.accountUpdate
    }, options));
  },
  AddressTypeUpdate: function AddressTypeUpdate(client, options) {
    return client.mutate(_objectSpread({
      mutation: Address.setCustomerDefaultAddress
    }, options));
  },
  CreateUserAddress: function CreateUserAddress(client, options) {
    return client.mutate(_objectSpread({
      mutation: Address.createUserAddress
    }, options));
  },
  DeleteUserAddress: function DeleteUserAddress(client, options) {
    return client.mutate(_objectSpread({
      mutation: Address.deleteUserAddress
    }, options));
  },
  PasswordChange: function PasswordChange(client, options) {
    return client.mutate(_objectSpread({
      mutation: User.changeUserPassword
    }, options));
  },
  SetPassword: function SetPassword(client, options) {
    return client.mutate(_objectSpread({
      mutation: User.setPassword
    }, options));
  },
  UpdateUserAddress: function UpdateUserAddress(client, options) {
    return client.mutate(_objectSpread({
      mutation: Address.updateUserAddress
    }, options));
  }
};