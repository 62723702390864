import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { styled } from "@styles";
import { spacer } from "@styles/constants";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: ", ";\n"])), function (props) {
  return "".concat(props.theme.spacing.spacer, " ").concat(spacer / 2, "rem");
});
export var Link = styled.a(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  path {\n    transition: 0.3s;\n  }\n\n  &:hover {\n    path {\n      fill: ", ";\n    }\n  }\n"])), function (props) {
  return props.theme.colors.primary;
});