import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import { styled, media } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-top: 30px;\n  margin-top: 30px;\n  border-top: 1px solid rgba(50,50,50,0.1);\n\n  ", "\n\n  ", "\n\n  h4 {\n    font-weight: 600;\n    margin-bottom: 20px;\n  }\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    text-align: center;\n  "]))), function (props) {
  return props.detail && "\n    margin-bottom: 30px;\n  ";
});
export var TimeLimit = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: 16px!important;\n  font-weight: 400;\n  margin-bottom: 40px;\n  opacity: 1!important;\n\n  span {\n    font-weight: 600;\n    color: #0a2540;\n    font-size: 16px!important;\n  }\n\n  ", "\n"])), function (props) {
  return props.detail && "\n    margin-bottom: 20px;\n  ";
});
export var WrapperDetail = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-flow: row wrap;\n  align-items: center;\n"])));
export var WrapperImgCode = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 30%;\n  padding-right: 15px;\n\n  ", "\n"])), media.smallScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    width: 100%;\n    margin-bottom: 20px;\n    padding-right: 0;\n  "]))));
export var WrapperDetailCode = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 70%;\n  padding-left: 15px;\n\n  ", "\n\n  h5 {\n    margin-bottom: 20px;\n  }\n\n  span {\n    font-size: 24px;\n    font-weight: 600;\n  }\n\n  p {\n    font-size: 14px;\n    color: #4b4b4b;\n    opacity: 0.5;\n    font-weight: 600;\n    margin-top: 20px;\n  }\n\n  .image-ic {\n    margin-top: 10px;\n\n    img {\n      margin-right: 15px;\n    }\n  }\n"])), media.smallScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    width: 100%;\n  "]))));