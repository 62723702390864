import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { styled } from "@styles";
export var ShippingMethodForm = styled.form(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n"])));
export var Title = styled.h3(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Tile = styled.label(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: block;\n  background-color: ", ";\n  padding: 20px;\n  ", "\n  cursor: pointer;\n"])), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #00AB4E;";
});
export var TileTitle = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-size: ", ";\n"])), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Price = styled.span(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: #00AB4E;\n"])));