import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { media, styled } from "@styles";
var padding = {
  md: "0.9rem 3.7rem",
  sm: "0.1rem 2rem"
};

var fontSize = function fontSize(_fontSize, smallFontSize) {
  return {
    md: _fontSize,
    sm: smallFontSize
  };
};

export var Primary = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: #00AB4E;\n  padding: ", ";\n  border: none;\n  transition: 0.3s;\n  outline: none;\n  cursor: pointer;\n  color: ", ";\n  width: ", "\n  \n  &:disabled {\n    background-color: ", ";\n\n    &,\n    &:hover {\n      cursor: default;\n    }\n  }\n\n  /**\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n    box-shadow: -3px 3px 14px 0px rgba(129, 67, 67, 0.2);\n  }\n  **/\n  \n\n  ", "\n"])), function (props) {
  return padding[props.size];
}, function (props) {
  return props.theme.button.colors[props.color].color;
}, function (props) {
  return props.fullWidth ? "100%" : "auto";
}, function (props) {
  return props.theme.colors.disabled;
}, function (props) {
  return props.theme.button.colors[props.color].hoverBackground;
}, function (props) {
  return props.theme.button.colors[props.color].hoverColor;
}, function (props) {
  return props.theme.button.colors[props.color].activeBackground;
}, media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding:  0.9rem 1rem;\n    width: ", ";\n  "])), function (props) {
  return props.fullWidth ? "100%" : "88%";
}));
export var Secondary = styled(Primary)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  box-shadow: inset 0px 0px 0px 3px #00AB4E;\n  border-left: 1px solid #00AB4E;\n  border-right: 1px solid #00AB4E;\n  color: #00AB4E;\n  background: #fff;\n"])));
export var Text = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n"])), function (_ref) {
  var size = _ref.size,
      typography = _ref.theme.button.typography;
  return fontSize(typography.fontSize, typography.smallFontSize)[size];
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.baseLineHeight;
});