import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100%;\n  /* height: auto; */\n"])));
export var MenuHeader = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-family: Nunito Sans;\n  font-style: normal;\n  font-weight: 800;\n  font-size: 30px;\n  line-height: 41px;\n  color: #0A2540;\n  padding-bottom: 2rem;\n"])));
export var MenuItem = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  padding-bottom: 1.5rem;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: ", ";\n  font-size: 18px;\n  line-height: 25px;\n  color: ", ";\n"])), function (props) {
  return props.active ? "bold" : "normal";
}, function (props) {
  return props.active ? "#0095DA" : "#0A2540";
});