import { BatchHttpLink } from "apollo-link-batch-http";
import { RetryLink } from "apollo-link-retry";
import { authLink, invalidTokenLinkWithTokenHandler } from "./auth";

/**
 * Creates list of links for Apollo client.
 * @param linksConfig Configuration for created links.
 */
export var createSaleorLinks = function createSaleorLinks(_ref) {
  var apiUrl = _ref.apiUrl,
      tokenExpirationCallback = _ref.tokenExpirationCallback;
  var invalidTokenLink = invalidTokenLinkWithTokenHandler(tokenExpirationCallback);
  return [invalidTokenLink, authLink, new RetryLink(), new BatchHttpLink({
    credentials: "include",
    uri: apiUrl
  })];
};