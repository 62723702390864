import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import { styled } from "@styles";
export var DiscountForm = styled.form(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Input = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-bottom: ", ";\n"])), function (props) {
  return props.theme.spacing.spacer;
});
export var InputWithButton = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n"])));
export var InputWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  flex: 1;\n"])));
export var ButtonWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: auto;\n  min-width: 110px;\n\n  button {\n    padding: 0.8rem 1rem;\n  }\n"])));
export var ChipsWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin: 0.4rem 0 0 0;\n"])));