import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;

import { media, styled } from "@styles";
import { css } from "styled-components";
var textProps = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0 0 0.5rem 0;\n  text-align: left;\n"])), function (props) {
  return props.theme.typography.baseFontSize;
});
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  text-align: center;\n  max-height: 30rem;\n  transition: 0.3s;\n"])));
export var Title = styled.h4(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-weight: normal;\n  margin-top: 20px;\n  font-family: 'Open Sans';\n  font-style: normal;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 22px;\n  color: #0A2540;\n  text-align: left;\n\n  ", "\n"])), media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    font-size: 16px;\n  "]))));
export var SubTitle = styled.h5(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-weight: normal;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 19px;\n  color: #5F5959;\n  text-align: left;\n"])));
export var Price = styled.p(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 20px;\n  line-height: 27px;\n  text-align: left;\n  color: #0095DA;\n  // margin-top: 15px;\n\n  ", "\n"])), media.smallScreen(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    font-size: 16px;\n  "]))));
export var UndiscountedPrice = styled.span(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  margin-top: 10px;\n  display: block;\n  text-align: left;\n\n  & > i {\n    background: $blue;\n    color: #fff;\n    font-size: 10px;\n    padding: 4px;\n    border-radius: 3px;\n    background: #0bc10a;\n    color: #fff;\n    font-size: 12px;\n    padding: 4px;\n    border-radius: 3px;\n  }\n\n  & > span {\n    text-decoration-line: line-through;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    -webkit-text-decoration-line: line-through;\n    color: #B6B6B6;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    font-size: 14px;\n  "]))));
export var Image = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    width: auto;\n    height: 305px;\n    max-width: 100%;\n    background: #fff;\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    overflow: hidden;\n\n  ", "\n\n  > img {\n    width: auto;\n    height: auto;\n    max-width: 100%;\n  }\n"])), media.largeScreen(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    height: 200px;\n  "]))));